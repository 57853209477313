<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header v-if="$store.state.irekaeMode" color="new">
            <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
        <ion-card-header v-else color="secondary">
            <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div class="conte">
              <ion-label position="floating">コンテナ識別番号</ion-label>
              <ion-input class="inp-cont" type="url" v-model="inpContUq"></ion-input>
          </div>
          <ion-button id="contnext" color="primary" expand="block" @click="readCont()">確認</ion-button>
          <ion-button id="contback" color="warning" @click="backPage">戻る</ion-button>
        </ion-card-content> 
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCardTitle, IonLabel, IonInput, IonCard, IonCardContent, IonButton, alertController } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Secchi',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonLabel,
    IonInput,
    IonCard,
    IonCardContent,
    IonButton
  },
  data(){
      return {
        inpContUq:'',
        memberId: '',
        contCd: '',
        errMsg: '',
      };
  },
  //ブラウザの戻るボタンを検知
  created() {
    window.addEventListener('popstate', this.handlePop);
  },
  //popstateを破棄
  beforeUnmount(){
    window.removeEventListener('popstate', this.handlePop);
  },
  computed: {
    title: function () {
      let name = '';
      if(this.$store.state.procType == this.$store.state.SECCHI){
        name = '設置';
      }else if(this.$store.state.procType == this.$store.state.TEKKYO){
        name = '撤去';
      }else if(this.$store.state.procType == this.$store.state.KOKAN){
        if(this.$store.state.irekaeMode){
          name = '入替';
        }else{
          name = '交換';
        }
      }else if(this.$store.state.procType == this.$store.state.KAKUNIN){
        name = '確認';
      }else if(this.$store.state.procType == this.$store.state.PHOTOS){
        name = '写真';
      }

      return name;
    }
  },
  ionViewDidEnter() {
    this.inpContUq = '';
  },
  methods: {
    handlePop: function (event) {
      //form の from to は？
      if (event.state && this.$store.state.procType !== this.$store.state.KOKAN) {
        if ((event.state.forward === "/genbalist"||event.state.forward === "/kakutei" || event.state.forward === "/select") && event.state.current === "/inpcont") {
          this.$router.push('Menu');
        }
      }
    },
    readCont: async function () {
      this.checkContInfo(this.inpContUq);
    },
    checkContInfo: async function (contUq) {
      // コンテナ識別番号からメンバーIDとコンテナCDを取得
      await this.axios.get(`mobile/get-contcd/${contUq}`).then( res => {
        if(res.data.result === 0) {
          this.memberId = res.data.member_id;
          if(this.$store.state.procType == this.$store.state.KOKAN && !this.$store.state.irekaeMode && this.$store.state.contCd2 == '') {
            // 処理タイプが交換で、１台目コンテナの読み取りの場合
            this.contCd = res.data.cont_cd;
            return this.chkContByCdFirst();
          }else {
            // 処理タイプが交換で2台目コンテナ読み取り、または交換以外の処理タイプ
            this.contCd = res.data.cont_cd;
            return this.chkContByCd();
          }

        }else{
          this.errMsg = res.data.msg;
          this.presentAlert();
          return;
        }
      })
    },
    // 交換時1台目の場合、貸出情報との整合性を確認
    chkContByCdFirst: async function () {

      const postData = {
        p_mid: this.memberId,
        cont_cd: this.contCd,
      }

      await this.axios.post('mobile/check-dispos', postData).then( res => {
        if(res.data.result === 0) {
          this.$store.commit('setContCd', { name: 'contCd2', contCd: this.contCd });
          return this.$router.push('select');
        }else{
          this.errMsg = res.data.msg;
          this.presentAlert();
          return;
        }
      })
    },
    // 貸出情報との整合性を確認
    chkContByCd: async function () {

      const postData = {
        p_mid: this.memberId,
        cont_cd1: this.contCd,
        cont_cd2: this.$store.state.contCd2,
        p_type: this.$store.state.procType,
        rsv_mode: this.$store.state.reserveMode,
        i_mode: this.$store.state.irekaeMode,
      }

      await this.axios.post('mobile/check-cont', postData).then( res => {
        if(res.data.result === 0) {
          this.$store.commit('setContCd', { name: 'contCd1', contCd: this.contCd });

          if(this.$store.state.procType == this.$store.state.PHOTOS){
            // 写真画面に遷移
            this.$router.push('photos');
          }else if(res.data.genba_select){
            // 現場選択画面に遷移
            this.$store.commit('readQrMode', {getFlg:true})
            this.$router.push('genbalist');
          }else{
            // コンテナ関連処理画面に遷移
            this.$router.push('kakutei');
          }
        }else{
          this.errMsg = res.data.msg;
          this.presentAlert();
          return;
        }
      })
    },
    presentAlert: async function () {
      const alert = await alertController.create({
        header: 'エラー',
        message: this.errMsg,
        buttons: ['閉じる']
      });

      await alert.present();
    },

    backPage: function(){
      this.$router.push('select');
    },
  },
});
</script>

<style scoped>
#video,
#canvas {
    justify-content: center;
    margin: 20px;
}
#canvas {
    display: none;
}
#qr {
    margin-left: 10px;
}
.tokui {
    margin-left: 20px;
}
#next,
#contnext {
    margin-left: 20px;
    margin-right: 20px;
}
#back,
#contback {
    margin: 20px;
}
#title {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.tokui-text {
    font-size: 20px;
    text-decoration: underline;
    color: rgb(25, 148, 170);
}
.conte {
    margin: 20px;
}
.inp-cont {
    border: solid 0.5px;
    border-color: #ddd;
    border-radius: 5px;
}
</style>