<template>
  <ion-header>
    <div class="allow-title">
      <ion-toolbar>
        <ion-buttons slot="end">
          <a v-if="delFlg" @click="presentAlertConfirm" style="margin-right:5px;">写真を削除</a>
          <ion-button @click="closeModal()"><ion-icon name="close"></ion-icon></ion-button>
        </ion-buttons>
      </ion-toolbar>
    </div>
  </ion-header>
  <ion-content class="ion-padding">
    <img :src="'data:image/png;base64,'+photoData" class="photo" width="300" height="300"/>
  </ion-content>
</template>

<style>
.photo{
  object-fit:cover;
}
</style>

<script>
import { IonContent, IonHeader, IonToolbar, IonButton, IonButtons, IonIcon, alertController } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ImageModal',
  props: {
    mode: { type: Number, default: 0 }, // 0. 購入時 1. 貸出時
    delFlg: { type: Boolean, default: false },
    rentNo: { type: Number, default: 0 },
    contCd: { type: Number, default: 0 },
    imageNo: { type: String, default: '' },
    status: { type: String, default: '' },
    close: { type: Function },
  },
  mounted(){
    this.getPhotoData();
  },
  data() {
    return {
        photoData: '',
    };
  },
  components: { IonContent, IonHeader, IonToolbar, IonButton, IonButtons, IonIcon },
  emits: ['close-modal'],
  methods: {
    getPhotoData() {
      if(this.mode === 0){
        const postData = {
          contCd: this.contCd,
          imageNo: this.imageNo,
        };
        this.axios.post("mobile/get-cont-photo",postData)
        .then((res) => {
          if(res.data.result == 0){
            this.photoData = res.data.photo;
          }
        })
      }else{
        const postData = {
          rentNo: this.rentNo,
          status: this.status,
          imageNo: this.imageNo,
        };
        this.axios.post("mobile/get-rent-photo",postData)
        .then((res) => {
          if(res.data.result == 0){
            this.photoData = res.data.photo;
          }
        })
      }
    },
    async presentAlertConfirm() {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: '確認',
          message: '写真を削除してもよろしいですか？',
          buttons: [
            {
              text: 'キャンセル',
              role: 'cancel',
              cssClass: 'secondary',
              id: 'cancel-button',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              },
            },
            {
              text: 'OK',
              id: 'confirm-button',
              handler: () => {
                this.delPhoto();
              },
            },
          ],
        });
      return alert.present();
    },
    delPhoto(){
      const postData = {
        rentNo: this.rentNo,
        status: this.status,
        imageNo: this.imageNo,
      };
      this.axios.post("mobile/del-photo",postData)
      .then((res)=>{
        console.log(res)
        if(res.data.result == 0){
          this.closeModal();
        }
      })
    },
    closeModal: function () {
      this.$emit('close-modal');
    },
  }
});
</script>