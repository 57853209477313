<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-card class="title">
                <ion-card-header color="secondary">
                    <ion-card-title class="ion-text-center" size="xx-large">設置</ion-card-title>
                </ion-card-header>
                <ion-toolbar color="medium" >
                    <ion-card-title class="toolber-font">現場詳細</ion-card-title>
                </ion-toolbar>
                <ion-card-content lines="inset">
                    <ion-list>
                        <ion-item>
                            <ion-label style="font-size:0.7em">得意先</ion-label>
                            <ion-label style="margin-top:-0.2em; padding-left:0.7em;" class="item-text">{{tokuiName}}</ion-label>
                        </ion-item>
                        <ion-item >
                            <ion-label style="font-size:0.7em">設置現場</ion-label>
                            <ion-label style="margin-top:-0.2em; padding-left:0.7em;" class="item-text">{{genbaName}}</ion-label>
                        </ion-item>
                        <ion-item>
                            <ion-label style="font-size:0.7em">設置現場住所</ion-label>
                            <ion-label style="margin-top:-0.2em; padding-left:0.7em;" class="item-text">{{genbaAddress}}</ion-label>
                        </ion-item>
                    </ion-list>
                    <ion-footer>
                        <div>
                        <ion-button id="kakutei-back" expand="block" color="primary" @click="clickKakutei()">確定</ion-button>
                        </div><div>
                        <ion-button id="kakutei-back" expand="block" color="warning" @click="clickBack()">戻る</ion-button>
                        </div>
                    </ion-footer>
                </ion-card-content>



            </ion-card>
        </ion-content>
    </ion-page>
</template>
<style scoped>
.kakutei-back {
  margin:20px;
}
.toolber-font {
  color:#ffffff;
  
  font-size:medium;
  text-align: center;
  text-size-adjust: 10px;
  margin-bottom: 1px;
}
.item-text {
    overflow: hidden;
    padding-top:10px;
    padding-bottom:10px;
}
.btn-inner {
  border:solid;
  flex-flow: colum;
  width:80%;
  
}
</style>
<script>
import {IonContent, IonPage, IonCardTitle, IonCard, IonCardHeader, IonCardContent, IonButton} from '@ionic/vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ReserveDetails',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonButton,
  },
  data(){
      return {
        tokuiName: '',
        genbaName: '',
        genbaAddress: '',
      };
  },

  //ページがアクティブになる直前に実行
  ionViewWillEnter(){
      this.init()
  },
  methods:{
    async init(){
        this.tokuiName = this.$store.state.tokuiName;
        this.genbaName = this.$store.state.genbaName;
        this.genbaAddress = this.$store.state.genbaAddress;
    },
    /*
    ここから先の処理はこちらのメソッドを使ってください。
     */
    clickKakutei(){
        this.$router.push('/kakutei');
    },
    clickBack(){
        this.$store.commit('clearRentInfo');
        this.$router.back();
    },
  }
})
</script>