<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header v-if="$store.state.irekaeMode" color="new">
            <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
        <ion-card-header v-else color="secondary">
            <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <!-- List Inset Lines -->
          <div class="content">
            <ion-item class="ion-text-center">コンテナ{{title}}手続きを確定しました。</ion-item>
          </div>
          <ion-button class="btn" color="primary" expand="block" @click="clickPhoto">写真を撮影</ion-button>
          <ion-button class="btn" color="primary" expand="block" @click="clickMenu">MENUへ</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonContent, IonPage, IonCardTitle, IonCard, IonButton, IonItem, alertController } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Kanryo',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonButton,
    IonItem
  },
  data(){
      return {
      };
  },
  computed: {
    title: function () {
      let name = '';
      if(this.$store.state.procType == this.$store.state.SECCHI){
        name = '設置';
      }else if(this.$store.state.procType == this.$store.state.TEKKYO){
        name = '撤去';
      }else if(this.$store.state.procType == this.$store.state.KOKAN){
        if(this.$store.state.irekaeMode){
          name = '入替';
        }else{
          name = '交換';
        }
      }else if(this.$store.state.procType == this.$store.state.KAKUNIN){
        name = '確認';
      }

      return name;
    }
  },
  methods: {
    // メニュー画面へ遷移
    clickMenu: function () {
      if(this.$store.state.procType == this.$store.state.KOKAN && !this.$store.state.irekaeMode){
        this.photoCheck();
      }else{
        this.$router.push("/menu");
      }
    },
    // 写真画面へ
    clickPhoto: function () {
      this.$router.push("/photos");
    },
    // エラーアラート表示
    photoCheck: async function () {
      const alert = await alertController.create({
        header: '確認',
        message: '回収したコンテナの写真を撮影する場合は、MENU画面の写真ボタンから撮影をしてください。',
        buttons: [
          { 
            text: 'OK',
            handler: () => {
              this.$router.push('/menu');
            }
          }
        ]
      });

      await alert.present();
    }
  }
});
</script>

<style scoped>
.content {
  margin-top:10px;
}
.btn {
  margin:20px;
}
#back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}

</style>