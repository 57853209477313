<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">状況</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div style="vertical-align: middle;text-align: center; margin-top:5px; margin-bottom: 0px; font-size:10pt">
            <img style="" src="/img/maker_blue.png">設置中
            <img style="margin-left:10px" src="/img/maker_purple.png">交換依頼中
            <img style="margin-left:10px" src="/img/maker_red.png">撤去依頼中
          </div>
          <ion-card class="map-disp">
              <div id="map" :style="mapHeight"></div>
          </ion-card>
          <ion-row>
            <ion-button id="jokyo-back" color="warning" @click="backPage">戻る</ion-button>
            <ion-button style="margin-left:auto;" id="jokyo-back" color="primary" @click="openSearch">詳細検索</ion-button>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-content>
    <ion-modal
      :is-open="isOpenRef"
      css-class="my-custom-class"
      @onDidDismiss="setOpen(false)"
      show-backdrop="true"
    >
      <Modal :msg="keiroMsg" v-on:close-modal="closeModal" v-on:show-setting="showSetting"></Modal>
    </ion-modal>
    <ion-modal
      :is-open="isOpenSettingRef"
      css-class="my-setting-class"
      @onDidDismiss="setSettingOpen(false)"
      show-backdrop="true"
    >
      <AllowAccess v-on:close-modal="closeModal" :settingType="settingType"></AllowAccess>
    </ion-modal>

    <ion-modal
      :is-open="isOpenGenbaRef"
      css-class="my-setting-class"
      @onDidDismiss="setGenbaOpen(false)"
      show-backdrop="true"
    >
    <GenbaInfoModal  v-on:close-modal="closeModal" :marker="marker" ></GenbaInfoModal>
    </ion-modal>

    <ion-modal
      :is-open="isOpenPhotoRef"
      css-class="my-setting-class"
      @onDidDismiss="setPhotoOpen(false)"
      show-backdrop="true"
    >
      <ImageModal v-if="resetFlg" v-on:close-modal="closeModal" :rentNo="rentNo" :contCd="contCd" :imageNo="imageNo" mode="1"></ImageModal>
    </ion-modal>
    
    <ion-modal
      :is-open="isOpenSearchRef"
      :value="setSearchOpen(false)"
      show-backdrop="true"
    >
    <JyokyoSearchModal v-on:close-modal="closeModal" v-on:add-marker="addMarker" v-model:cd="cdComputed" v-model:tokuiName="tokuiNameComputed" v-model:genbaName="genbaNameComputed" v-model:contType="contTypeComputed" v-model:contStatus="contStatusComputed" v-model:contTypeList="contTypeList"></JyokyoSearchModal>
    </ion-modal>
  </ion-page>
</template>

<script>
//import { IonContent, IonPage, IonCardTitle,  IonCard, IonCardContent, IonButton, alertController, IonModal, modalController } from '@ionic/vue';
import { IonContent, IonPage, IonCardTitle,  IonCard, IonCardContent, IonButton,  IonModal, modalController, alertController } from '@ionic/vue';
import { defineComponent, ref, computed, } from 'vue';
import "leaflet/dist/leaflet.css"
import L from "leaflet";
require("leaflet.markercluster")
require("leaflet-easybutton")
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-easybutton/src/easy-button.css';
import '@fortawesome/fontawesome-free/css/all.css';
import Modal from '../components/Modal.vue';
import AllowAccess from '../components/AllowAccess.vue';
import ImageModal from '../components/ImageModal.vue';
import GenbaInfoModal from '../components/GenbaInfoModal.vue';
import JyokyoSearchModal from '../components/JokyoSearchModal.vue';
require("../assets/leaflet.sprite");


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

L.Icon.Default.imagePath = '../assets/img';

export default defineComponent({
  name: 'Jokyo',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonCardContent,
    IonButton,
    Modal,
    AllowAccess,
    ImageModal,
    IonModal,
    GenbaInfoModal,
    JyokyoSearchModal,
  },
  setup() {
    // 位置情報許可設定用のダイアログ表示制御
    const isOpenRef = ref(false);
    const isOpenSettingRef = ref(false);
    const isOpenPhotoRef = ref(false);
    const isOpenGenbaRef = ref(false);
    const isOpenSearchRef = ref(false)
    const setOpen = (state) => isOpenRef.value = state;
    const setSettingOpen = (state) => isOpenSettingRef.value = state;
    const setPhotoOpen = (state) => isOpenPhotoRef.value = state;
    const setGenbaOpen = (state) => isOpenGenbaRef.value = state;
    const setSearchOpen = (state) => isOpenSearchRef.value = state;
    const refGenbaInfoModal = ref();
    const test1 = ref('test1-0');

    const cd=ref('');
    const cdComputed = computed({
      get: () => cd.value,
      set: (value) => (cd.value = value),
    });
    const tokuiName=ref('');
    const tokuiNameComputed = computed({
      get: () => tokuiName.value,
      set: (value) => (tokuiName.value = value),
    });
    const genbaName=ref('');
    const genbaNameComputed = computed({
      get: () => genbaName.value,
      set: (value) => (genbaName.value = value),
    });
    const contType=ref('');
    const contTypeComputed = computed({
      get: () => contType.value,
      set: (value) => (contType.value = value),
    });
    const contStatus=ref([]);
    const contStatusComputed = computed({
      get: () => contStatus.value,
      set: (value) => (contStatus.value = value),
    });

    const keiroMsg = '経路を表示するには位置情報へのアクセスを許可する必要があります。';
    return { isOpenRef, isOpenSettingRef, isOpenPhotoRef, isOpenGenbaRef, setOpen, setSettingOpen, setPhotoOpen, setGenbaOpen, keiroMsg, refGenbaInfoModal, test1, setSearchOpen, isOpenSearchRef,cdComputed, tokuiNameComputed, genbaNameComputed, contTypeComputed, contStatusComputed}
  },
  data(){
      return {
        mapHeight:{
          height:'500px'
        },
        url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
        mapWidth: 500,
        sepaPx:50,
        zoom: 15,
        center: [35.68944,139.69167],
        markers:[],
        markerGroup:[],
        status: '',
        rentNo: '',
        name:'',
        address:'',
        startDate: '',
        days: '',
        biko:'',
        imageNo:'',
        resetFlg:true,
        currentLat:'',
        currentLng:'',
        lat:'',
        lng:'',
        markerUpdate: true,
        map: {},
        layer: {},
        groupLayer: {},
        mapIconGreen: {},
        mapIconRed: {},
        contTypeList: {},

        //設定方法 0:iphone 1:android
        settingType: 0,
        marker:{},
            test2:'test2-0',
      };
  },
  created(){
    //console.log("hei=" + document.documentElement.clientHeight);
    this.mapHeight.height = document.documentElement.clientHeight-300 + "px";
  },
  async mounted(){
    //var self = this;
    //self.getPosition();

    this.$nextTick(()=>{
      this.test1="test1-1"
      this.test2="test2-1"
    }
    )
    
    //コンテナ種類取得
    await this.getContTypeList();


    //---- アイコンを作成
    this.mapIconRed = L.icon({
        iconUrl: "assets/img/marker-red-icon.png",
        iconRetinaUrl: "assets/img/marker-red-icon-2x.png",
        shadowUrl: "assets/img/marker-shadow.png",
        iconSize:[25,41],
        shadowSize: [41, 41],
        iconAnchor: [13, 40],
        shadowAnchor: [12, 40],
        popupAnchor: [0, -32]
    });

    // コンテナ設置位置にマーカー設定
    this.$nextTick(
      () => {
        setTimeout(()=>{
        this.layer = new L.tileLayer(this.url, {opacity:0.7});
        this.map = new L.Map("map", {
          center: L.latLng(35.68944,139.69167),
          zoom: 15,
        });
        this.layer.addTo(this.map)
        this.addMarker();
        // L.easyButton('fa-external-link-alt', () => {
        //   this.presentAlert();
        // }).addTo(this.map)
        },500)
      }
    )

   
  },
  methods: {
            clicktest(){

            //this.$nextTick(()=>{
            this.test1="test1-click"
            this.test2="test2-click"
            this.$forceUpdate();
            console.log("click!\n")
            ///});

        },
    // マーカー追加
    addMarker: async function () {
      
      //一旦マップからマーカーを削除
      this.map.removeLayer(this.groupLayer);
      const postData = {
        tokui_cd : this.cdComputed,
        tokui_name : this.tokuiNameComputed,
        genba_name : this.genbaNameComputed,
        cont_type : this.contTypeComputed,
        cont_status : this.contStatusComputed,
      }
      var self = this;
      self.markers = [];
      await self.axios.post('map', postData).then(async (res) => {
        console.log(res.data.map.length)
        if(res.data.map.length==0){
          const alert = await alertController.create({
          header: 'エラー',
          // subHeader: '該当のデータはありません',
          message: '該当のデータはありません',
          buttons: ['OK'],
          });
          await alert.present();
        }
        self.markers = res.data.map;
        this.groupLayer = new L.markerClusterGroup({
          iconCreateFunction: function (cluster) {
            var markers = cluster.getAllChildMarkers();
            var kaishu_flg = false
            for (var i = 0; i < markers.length; i++) {
             if (markers[i].options.rent_status == 2 || markers[i].options.rent_status == 3) {
               kaishu_flg = true
             }
             var kaishu = kaishu_flg? 'red' : 'blue';
             console.log(kaishu)
            }
            return L.divIcon({ html: '<div><span>' + markers.length + '</span></div>', className: 'marker-cluster marker-cluster-' + kaishu, iconSize: L.point(40, 40) });
          }
        });
        for ( let key in self.markers) {
          // if(self.markers[key].status == 2){
          //   self.groupLayer.addLayer(new L.Marker([self.markers[key].lat, self.markers[key].lng], {icon: this.mapIconRed, rent_status: 2}).on('click', () => {
          //     self.clickMark(self.markers[key]);
          //   }))
          // }else{
          //   self.groupLayer.addLayer(new L.Marker([self.markers[key].lat, self.markers[key].lng], {rent_status: 1}).on('click', () => {
          //     self.clickMark(self.markers[key]);
          //   }))
          // }
          let mColor = '';
          if(self.markers[key].status == 2) {
            mColor = 'purple';
          }else if(self.markers[key].status == 3) {
            mColor = 'red';
          }
          self.groupLayer.addLayer(new L.Marker([self.markers[key].lat, self.markers[key].lng], {
            icon: L.spriteIcon(mColor),
            rent_status: self.markers[key].status,
          }).on('click', () => {
            self.clickMark(self.markers[key]);
          }))
        }
        self.groupLayer.addTo(self.map)
        self.map.fitBounds(self.groupLayer.getBounds());
      })
    },
    // マーカークリック時にコンテナの情報を表示
    clickMark: function (mark) {
      //this.refGenbaInfoModal.dlgOpen(mark);
      this.openGenbaModal(mark)
      /*
      this.status = this.getStatusName(mark.status);
      this.rentNo = mark.rent_no;
      this.genbaName = mark.genba_name;
      this.address = mark.address;
      this.startDate = mark.rent_start_date;
      this.days = mark.rent_days;
      this.biko = mark.genba_biko;
      this.currentLat = mark.lat;
      this.currentLng = mark.lng;


      const postData = {
        rentNo: mark.rent_no,
        contCd: mark.cont_cd,
      }

      this.axios.post('mobile-getphotos', postData).then((res)=> {
        if(res.data.result == 0){
          console.log(res)
          this.photos = res.data.photos;
        }
      })

      const button = document.getElementById('keiro');
      button.disabled = false;
      */
    },
    // 経路表示
    clickKeiro: function(){
      this.getPosition();
    },
    // 戻る
    backPage: function(){
      this.$router.back();
    },
    getPosition:function(){
      navigator.geolocation.getCurrentPosition(this.get_coord, this.err);
    },
    get_coord: function (position) {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;

      window.location.href=`https://www.google.com/maps/dir/?api=1&origin=${lat},${lng}&destination=${this.currentLat},${this.currentLng}&travelmode=driving`;
    },
    err: function () {
      this.setOpen(true);
    },
    // Googleマップ遷移の確認ダイアログ
    // presentAlert: async function () {
    //   const alert = await alertController
    //     .create({
    //       header: '確認',
    //       message: 'Google Mapで開きますか？',
    //       buttons: ['キャンセル',
    //                 { text: 'OK',
    //                   role: 'cancel',
    //                   handler: () => {
    //                     this.openGmap();
    //                   }}
    //               ]
    //     });
    //    await alert.present();
    // },
    // Googleマップへ遷移
    // openGmap: function () {
    //   var curCenter = this.map.getCenter();
    //   var curZoom = this.map.getZoom();

    //   window.location.href=`https://www.google.com/maps/@?api=1&map_action=map&center=${curCenter.lat},${curCenter.lng}&zoom=${curZoom}`;
    // },
    // 位置情報設定ダイアログを閉じる
    closeModal: function () {
      modalController.dismiss();
    },
    // 位置情報設定ダイアログを表示
    showSetting: function (val) {
      this.$nextTick(() => {
        this.settingType = val;
        this.setSettingOpen(true);
      })
    },
    // コンテナ拡大写真ダイアログ表示
    openPhotoModal: function (no){
      this.imageNo = no;
      this.$refs
      this.setPhotoOpen(true);
    },
    // コンテナ拡大写真ダイアログ表示
    openGenbaModal: function (val){
      this.marker = val;
      this.setGenbaOpen(true);
    },
    openSearch: function (){
      this.setSearchOpen(true);
    },
    // 拠点取得
    getContTypeList: function () {
      this.axios.get(`mobile/get-conttype-list`).then( res => {
        if(res.data.result == 0) {
        this.contTypeList = [{type_cd: '', type_name:'全て表示', size_name:'サイズ'} , ...res.data.contTypeList];
        }
      })
    },
  },
});
</script>

<style scoped>
.disp-genba {
  margin-left:10px;
}

.disp-date{
  margin-left:20px;
}

.tokui {
  margin-left:20px;
  margin-top:30px;
}

#keiro {
  margin-left:20px;
  margin-right:20px;
}

#jokyo-back {
  margin:10px;
}

#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}

.genba-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}

#map {
  width: 100%;
  /*height:200px;*/
}

.map-disp {
  width: 100%;
  margin-top:10px;
  margin-left: auto;
  margin-right: auto;
}

.photo {
  margin:2px;
}

</style>