<template>
    <div>
        <ion-header>
            <div class="allow-title">
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-button @click="closeModal()">
                            <ion-icon name="close"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </div>
        </ion-header>
        <ion-content fullscreen class="ion-padding">
            <div style="max-height:250px;">
            <ion-text color="medium" class="txt-title">コンテナ</ion-text>
            <p class="txt-content">{{ marker.cont_cd }}:{{ marker.type_name }}[{{ marker.size_name }}]</p>

            <ion-text color="medium" class="txt-title">状態</ion-text>
            <p class="txt-content">{{jyotaiName }}</p>

            <ion-text color="medium" class="txt-title">得意先</ion-text>
            <p v-if="marker.tokui_name" class="txt-content">
                {{ marker.tokui_name }}
            </p>
            <p v-else class="txt-content">未設定</p>

            <ion-text color="medium" class="txt-title">設置日</ion-text>
            <p class="txt-content">{{ this.rentDate }}</p>

            <ion-text color="medium" class="txt-title">現場名</ion-text>
            <p v-if="marker.genba_name" class="txt-content">
                {{ marker.genba_name }}
            </p>
            <p v-else class="txt-content">未設定</p>

            <ion-text color="medium" class="txt-title">設置場所住所</ion-text>
            <p class="txt-content">{{ marker.address }}</p>
            <ion-button id="keiro" color="primary" expand="block" @click="clickKeiro" >経路</ion-button>


            <ion-text color="medium"><h6>備考(依頼)</h6></ion-text>
                {{ marker.comment }}

            <ion-text color="medium"><h6>備考(現場)</h6></ion-text>
                {{ marker.genba_biko }}

            <ion-text color="medium"><h6>備考(設置)</h6></ion-text>
                {{ marker.comment_setchi }}

            <ion-text color="medium"><h6>備考(回収)</h6></ion-text>
                {{ marker.comment_kaishu }}

            <ion-text color="medium"><h6>写真</h6></ion-text>
            <div class="photo-area">
                <span style="font-weight: bold">購入時状態</span>
                <div style="height: 100px; display: flex">
                    <div
                        v-for="(photo, index) in contPhotos"
                        :key="index"
                        class="img-area"
                    >
                        <img
                            @click="openModal(photo.no, 0)"
                            class="photo"
                            :src="'data:image/png;base64,' + photo.data"
                        />
                    </div>
                </div>
                <span style="font-weight: bold">依頼状態</span>
                <div style="height: 120px; display: flex">
                    <div
                        v-for="(photo, index) in iraiPhotos"
                        :key="index"
                        class="img-area"
                    >
                        <img
                            @click="openModal(photo.no, 1)"
                            class="photo"
                            :src="'data:image/png;base64,' + photo.data"
                        />
                        <span>{{ photo.statusName }}</span>
                    </div>
                </div>
                <span style="font-weight: bold">貸出中状態</span>
                <div style="height: 100px; display: flex">
                    <div
                        v-for="(photo, index) in photos"
                        :key="index"
                        class="img-area"
                    >
                        <img
                            @click="openModal(photo.no, 1, photo.status)"
                            class="photo"
                            :src="'data:image/png;base64,' + photo.data"
                        />
                        <span>{{ photo.statusName }}</span>
                    </div>
                </div>
            </div>
            <ion-button id="jokyo-back" color="warning" style="margin-top:15px" expand="block" @click="closeModal" >閉じる</ion-button>
            </div>
        </ion-content>
        <ion-modal
            :is-open="isOpenKeiroRef"
            css-class="my-custom-class"
            @onDidDismiss="setKeiroOpen(false)"
            show-backdrop="true"
        >
            <Modal :msg="keiroMsg" v-on:close-modal="closeModal" v-on:show-setting="showSetting"></Modal>
        </ion-modal>
        <ion-modal
            :is-open="isOpenSettingRef"
            css-class="my-setting-class"
            @onDidDismiss="setSettingOpen(false)"
            show-backdrop="true"
        >
            <AllowAccess v-on:close-modal="closeModal" :settingType="settingType"></AllowAccess>
        </ion-modal>

        <ion-modal :is-open="isOpenRef" css-class="my-setting-class" :value="setOpen(false)" show-backdrop="true">
            <ImageModal v-on:close-modal="closeImage" :rentNo="rentNo" :contCd="contCd" :imageNo="imageNo" :mode="modalMode" :status="photoStatus"></ImageModal>
        </ion-modal>
    </div>
</template>
<style scoped>
/**タイトル */
.txt-title {
    font-size: 1.1em;
}
/**内容 */
.txt-content {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding-right: 1em;
    padding-left: 1em;
    margin-top: 3px;
    text-align: justify;
    text-justify: inter-ideograph;
    font-size: 1.2em;
}
.photo-area {
    width: 100%;
}
.photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #888;
}
.img-area {
    width: 70px;
    height: 80px;
    padding: 3px;
    text-align: center;
}
.close-button{
    margin-top:15px;
}
</style>
<script>
import { defineComponent,ref } from "vue";
import {
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,modalController, IonModal
} from "@ionic/vue";
import Modal from '../components/Modal.vue';
import AllowAccess from '../components/AllowAccess.vue';
import ImageModal from '../components/ImageModal.vue';

export default defineComponent({
    name: "GenbaInfoModal",
    components: { IonHeader, IonToolbar, IonButton, IonButtons, IonIcon, IonModal, Modal, AllowAccess, ImageModal},
    props: {
        /** マップのマーカーの値(現場の情報) */
        marker: { type: Object },
    },
    setup(){
        // 位置情報モーダル
        const isOpenKeiroRef = ref(false);
        const setKeiroOpen = (state) => isOpenKeiroRef.value = state;
        const keiroMsg = '経路を表示するには位置情報へのアクセスを許可する必要があります。';

        // 位置情報設定説明モーダル
        const isOpenSettingRef = ref(false);
        const setSettingOpen = (state) => isOpenSettingRef.value = state;

        // 写真モーダル
        const isOpenRef = ref(false);
        const setOpen = (state) => isOpenRef.value = state;
        return { isOpenKeiroRef, setKeiroOpen, keiroMsg, isOpenSettingRef, setSettingOpen, isOpenRef, setOpen }
    },
    mounted() {
        this.getPhoto();
        //設置日
        this.rentDate= this.dateFormat(this.marker.rent_start_date);
        if(this.rentDate){
            this.rentDate = this.rentDate + " (設置日数："+this.marker.rent_days+" 日)";
        }
        this.jyotaiName = this.getStatusName(this.marker.status);
    },
    data() {
        return {
            contPhotos: [],
            iraiPhotos: [],
            photos: [],

            imageNo: 0,
            modalMode: 0,
            status: '0',
            photoStatus: '',
            delFlg: false,
            rentDate:'',
            jyotaiName:'',
            
            settingType:0,
            
        };
    },
    methods: {
        /**
         * 閉じる
         */
        closeModal() {
            this.$emit("close-modal");
        },
        /**
         * 写真情報の取得
         */
        async getPhoto() {
            this.photos = [];
            const postData = {
                rentNo: this.marker.rent_no,
                contCd: this.marker.cont_cd,
            };
            this.axios.post("/mobile/get-photos", postData).then((res) => {
                if (res.data.result == 0) {
                    this.photos = res.data.photos;
                    this.contPhotos = res.data.cont_photos;
                    this.iraiPhotos = res.data.irai_photos;
                }
            });
        },

        /**
         * 経路クリック
         */
        clickKeiro(){
            navigator.geolocation.getCurrentPosition(this.jumpMap, this.getPositionFail);
        },
        getPositionFail(){
            this.setKeiroOpen(true)
        },

        /**
         * To GoogleMa
         */
        jumpMap(position){
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            //open(`https://www.google.com/maps/dir/?api=1&origin=${lat},${lng}&destination=${this.marker.lat},${this.marker.lng}&travelmode=driving`, '_blank'); 
            window.location.href=`https://www.google.com/maps/dir/?api=1&origin=${lat},${lng}&destination=${this.marker.lat},${this.marker.lng}&travelmode=driving`;
        },
        // ダイアログを閉じる
        closeImage: function () {
            modalController.dismiss();
        },
        openModal(no, mode, status=''){
            this.imageNo = no;
            this.modalMode = mode;
            this.photoStatus = status;
            this.rentNo = this.marker.rent_no;
            this.contCd = this.marker.cont_cd
            this.setOpen(true);
        }, 
        // 位置情報設定ダイアログを表示
        showSetting: function (val) {
        this.$nextTick(() => {
            this.settingType = val;
            this.setSettingOpen(true);
        })
        },

    },
});
</script>