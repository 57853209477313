<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-card class="title">
                <ion-card-header color="secondary">
                    <ion-card-title class="ion-text-center" size="xx-large">設置</ion-card-title>
                </ion-card-header>
                <ion-toolbar color="medium">
                    <ion-card-title class="toolber-font">抽出条件入力</ion-card-title>
                </ion-toolbar>
                <ion-card-content>
                    <ion-list lines="inset">
                        <ion-list>
                            <div>
                                <ion-label>予約番号</ion-label>
                            </div>
                            <div>
                                <ion-input class="cell_css" type="number"  v-model="search.reserve_no" @input="search.reserve_no = $event.target.value"></ion-input>
                            </div>
                        </ion-list>
                        <ion-list class="disp-block">
                            <ion-label>予定日</ion-label>
                            <div>
                                <div class="txt-disp line-block" style="width:160px; margin-right:5px">
                                    <ion-datetime displayFormat="YYYY年MM月DD日"
                                        doneText="OK"
                                        class="ion-no-padding"
                                        cancelText="キャンセル"
                                        placeholder="日付を選択"
                                        v-model="search.reserve_date"
                                        picker-format="YYYY-MM-DD"
                                    ></ion-datetime>
                                </div>
                                <div class="line-block">
                                    <ion-button color="danger" @click="clickDateClear()">リセット</ion-button>
                                </div>
                            </div>
                        </ion-list>
                        <ion-list  style="margin-left:auto;">
                            <ion-label class="disp-block">得意先</ion-label>
                            <div class="txt-disp">{{search.tokui_name}}</div>
                            <ion-button color="primary" @click="tokuiModal()">選択</ion-button>
                            <ion-button color="danger" :disabled="!search.tokui_cd" @click="clickTokuiClear()">リセット</ion-button>
                        </ion-list>
                        <ion-list aria-readonly="true">
                            <ion-label class="disp-block">設置現場</ion-label>
                            <div class="txt-disp">{{search.genba_name}}</div>
                            <ion-button :disabled="!search.tokui_cd" color="primary" @click="genbaModal()">選択</ion-button>
                            <ion-button color="danger" :disabled="!search.genba_cd" @click="clickGenbaClear()">リセット</ion-button>
                        </ion-list>
                        <ion-list class="disp-block">
                            <ion-label>設置場所住所</ion-label>
                            <ion-input class="cell_css" :value="search.genba_address" @input="search.genba_address = $event.target.value"></ion-input>
                        </ion-list>                           
                        <ion-list>
                            <ion-button color="primary" expand="block" disabled="false" @click="clickSearch()">検索</ion-button>
                            
                        </ion-list>
                    </ion-list>
                <ion-footer>
                    {{mess}}
                    <ion-button id="kakutei-back" expand="block" color="warning" @click="clickBack()">戻る</ion-button>
                </ion-footer>
                </ion-card-content>
                <ion-modal
                :is-open="isOpenRefTokui"
                css-class="my-custom-class2"
                @onDidDismiss="setOpenTokui(false)"
                show-backdrop="true"
                >
                    <ReserveTokuiModal v-on:close-modal="closeModalTokui" v-on:click-select="tokuiModal"></ReserveTokuiModal>
                </ion-modal>
                <ion-modal
                :is-open="isOpenRefGenba"
                css-class="my-custom-class2"
                @onDidDismiss="setOpenGenba(false)"
                show-backdrop="true"
                >
                    <ReserveGenbaModal :tokui_cd="search.tokui_cd" v-on:close-modal="closeModalGenba" v-on:click-select="genbaModal"></ReserveGenbaModal>
                </ion-modal>
            </ion-card>
        </ion-content>
    </ion-page>
</template>
<style scoped>
.line-block{
    display: inline-block;
}
.txt-disp{
    margin-bottom: 5px;
    padding:5px;
    min-height: 2em;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.kakutei-back {
  margin:20px;
}
.disp-block {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.toolber-font {
  color:#ffffff;
  
  font-size:medium;
  text-align: center;
  text-size-adjust: 10px;
  margin-bottom: 1px;
}
.cell_css {
  border:solid;
  width: 90%;
  margin: 5px;
}
</style>
<script>
import {IonDatetime, IonContent, IonPage, IonCardTitle, IonCard, IonCardHeader, IonCardContent, IonButton,IonList,IonLabel,IonModal,modalController} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import ReserveTokuiModal from '../components/ReserveTokuiModal.vue';
import ReserveGenbaModal from '../components/ReserveGenbaModal.vue';
export default defineComponent({
  name: 'Reserve',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonButton,
    IonDatetime,
    IonList,
    IonLabel,
    IonModal,
    ReserveTokuiModal,
    ReserveGenbaModal,
  },
  props:['title', 'protype'],
  setup() {
            const tokuiOptions = {
            header: '得意先を選択してください。',
            translucent: true
            };
            const basyoOptions = {
            header: '設置場所を選択してください。',
            translucent: true
            };
            const isOpenRefTokui = ref(false);
            const isOpenRefGenba = ref(false);
            const setOpenTokui = (state) => isOpenRefTokui.value = state;
            const setOpenGenba = (state) => isOpenRefGenba.value = state;
        return {
            tokuiOptions,basyoOptions,setOpenTokui,isOpenRefTokui,isOpenRefGenba,setOpenGenba
        };
  },
  data(){
      return {
        search:  {
            reserve_no:'',
            tokui_cd: '',
            tokui_name: '',
            genba_cd: '',
            genba_address:'',
            reserve_date:'',
        },
        tokuisakiSelect:[],
        tokuisakiData:[],
        genbaData:[],
        test:'TEN01',
      };
  },
  ionViewDidEnter(){
    this.init();
  },
  methods:{
    init(){
        // ストアから検索条件を取得
        this.search = this.$store.state.rsvSearch;
        if(!new Date(this.search.reserve_date).valueOf()){
            //本日の日付を'YY-MM-DD'形式で取得
            let times = new Date()
            let y = times.getFullYear();
            let m = ('00' + (times.getMonth()+1)).slice(-2);
            let d = ('00' + times.getDate()).slice(-2);
            this.search.reserve_date = y + '-' + m + '-' + d
        }
        // ストアの検索条件をクリアする
        this.$store.commit('clearRsvSearch');
    },
      /*
      検索ボタン処理
       */
    async clickSearch(){
            //日付の中身が空欄(Invalid data)であったら空欄として返す。
            if(!new Date(this.search.reserve_date).valueOf()){
                this.search.reserve_date = ''
            }
            else{
                this.search.reserve_date=this.dateFormat(this.search.reserve_date)
            }
            //TODO 遷移先(抽出結果一覧画面)に、検索条件とタイトルを渡す。
            //リロードなどで保持データ消える可能性があるため、ストア使って保存
            // this.$router.push({
            //         name:'ReserveResult',params:{
            //             title:this.title,
            //             reserve_no:this.search.reserve_no,
            //             tokui_cd:this.search.tokui_cd,
            //             genba_cd:this.search.genba_cd,
            //             genba_address:this.search.genba_address,
            //             reserve_date:this.search.reserve_date,
            //         }
            //     })
            this.$store.commit('setRsvSearch', { search: this.search });
            this.$router.push('/reserveresult');
    },
    /*
    モーダルを閉じるボタン
    */
    closeModalTokui(){
        modalController.dismiss()
    },
    closeModalGenba(){
        modalController.dismiss()
    },
    /*
    選択ボタンクリック後、モーダルを開く
    */
    async tokuiModal(val){
        this.setOpenTokui(true)
        this.search.tokui_cd=val.tokui_cd
        this.search.tokui_name=val.tokui_name
        modalController.dismiss()
        
    },
    async genbaModal(val){
        this.setOpenGenba(true)
        this.search.genba_cd=val.genba_cd
        this.search.genba_name=val.genba_name
        modalController.dismiss()
    },
    /*
    クリアボタン処理:予約日の検索条件リセット
    */
    clickDateClear(){
        this.search.reserve_date = ''
    },
    clickTokuiClear(){
        this.search.tokui_cd = ''
        this.search.tokui_name = ''
        this.search.genba_cd = ''
        this.search.genba_name = ''
    },
    clickGenbaClear(){
        this.search.genba_cd = ''
        this.search.genba_name = ''
    },
    /*
      戻るボタン
       */
    clickBack(){
        this.$router.push({name: 'Menu'});
    },
  }
})
</script>