<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header v-if="$store.state.irekaeMode" color="new">
            <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
        <ion-card-header v-else color="secondary">
            <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
      <ion-card-content>
        <!-- List Inset Lines -->
        <ion-list lines="inset">
          <ion-item>
            <div class="disp-block">
              <ion-note>状態</ion-note>
              <ion-label slot="end">{{getStatusName(status)}}</ion-label>
            </div>
          </ion-item>
          <ion-item>
            <div class="disp-block">
              <ion-note>得意先</ion-note>
              <ion-label slot="end">{{tokuiName}}</ion-label>
            </div>
          </ion-item>
          <ion-item>
            <div class="disp-block">
              <ion-note>設置現場</ion-note>
              <ion-label slot="end">{{genbaName}}</ion-label>
            </div>
          </ion-item>
          <ion-item>
            <div class="disp-block">
              <ion-note>拠点</ion-note>
              <ion-select style="max-width: 100%" cancelText="キャンセル" :value="kyotenSel" :selected-text="kyotenSel.kyoten_name" @ionChange="kyotenSel = $event.detail.value" interface="action-sheet">
                <ion-select-option v-for="kyoten in kyotenList" :value="kyoten" :key="kyoten.kyoten_cd">{{ kyoten.kyoten_name }}</ion-select-option>
                <ion-select-option :value="kyotenNowhere">設定なし</ion-select-option>
              </ion-select>
            </div>
          </ion-item>
          <ion-item>
            <div class="disp-block">
              <ion-note>住所</ion-note>
              <ion-label slot="end">{{address}}</ion-label>
            </div>
          </ion-item>
          <ion-item>
            <div class="disp-block">
              <ion-note>コンテナ番号</ion-note>
              <ion-label v-if="$store.state.procType==$store.state.KOKAN && !$store.state.irekaeMode" slot="end">{{contCd1}}→{{contCd2}}</ion-label>
              <ion-label v-else slot="end">{{contCd1}}</ion-label>
            </div>
          </ion-item>
          <ion-item v-if="$store.state.procType!=$store.state.SECCHI">
            <div class="disp-block">
              <ion-note><span v-if="$store.state.procType==$store.state.KOKAN">前回</span>設置日</ion-note>
              <ion-label slot="end">{{startDate?dateFormat(startDate):''}}</ion-label>
              <div style="height:5px;"></div>
            </div>
          </ion-item>
          <ion-item v-if="$store.state.procType==$store.state.SECCHI || $store.state.procType==$store.state.KOKAN">
            <div class="disp-block">
              <ion-note>備考（設置)</ion-note>
              <ion-label slot="end">
                <textarea class="inp-style" v-model="bikoSecchi" maxlength="400" rows="5"></textarea>
                <!-- <textarea class="inp-style" :value="bikoSecchi" @input="bikoSecchi=$event.target.value" maxlength="400" rows="5"></textarea> -->
              </ion-label>
            </div>
          </ion-item>
          <ion-item v-if="$store.state.procType==$store.state.TEKKYO || $store.state.procType==$store.state.KOKAN">
            <div class="disp-block">
              <ion-note>備考（回収)</ion-note>
              <ion-label slot="end">
                <textarea class="inp-style" v-model="bikoTekkyo" maxlength="400" rows="5"></textarea>
                <!-- <ion-textarea class="inp-style" :value="bikoTekkyo" @input="bikoTekkyo=$event.target.value" maxlength="400" rows="5"></ion-textarea> -->
              </ion-label>
            </div>
          </ion-item>
        </ion-list>
        <div style="margin-top:40px; text-align: center;">
          <span v-show="contCdMissingFlg" style="color: red">コンテナ番号が入力されていません。</span>
        </div>
        <ion-button v-if="$store.state.procType!=$store.state.KAKUNIN" id="kakutei" color="primary" expand="block" :disabled="notBarrageFlg || contCdMissingFlg || loadingFlg" @click="clickKakutei">確定</ion-button>
        <ion-button v-else id="kyotenUpdate" color="primary" expand="block" :disabled="notBarrageFlg" @click="clickKyotenUpdate">拠点更新</ion-button>
        <ion-button id="kakutei-back" color="warning" @click="backPage">戻る</ion-button>
      </ion-card-content>
      </ion-card>
    </ion-content>
    <ion-modal
      :is-open="isOpenRef"
      css-class="my-custom-class"
      :value="setOpen(false)"
      show-backdrop="true"
    >
      <Modal :msg="secchiMsg" v-on:close-modal="closeModal" v-on:show-setting="showSetting"></Modal>
    </ion-modal>
    <ion-modal
      :is-open="isOpenSettingRef"
      css-class="my-setting-class"
      :value="setSettingOpen(false)"
      show-backdrop="true"
    >
      <AllowAccess v-on:close-modal="closeModal" :settingType="settingType"></AllowAccess>
    </ion-modal>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCardTitle, IonLabel, IonCard, IonCardHeader, IonCardContent, IonButton, IonList, IonItem, IonNote, modalController, IonModal, IonSelect, IonSelectOption,alertController  } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import Modal from '../components/Modal.vue'
import AllowAccess from '../components/AllowAccess.vue'

export default defineComponent({
  name: 'Kakutei',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonLabel,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonButton,
    IonList,
    IonItem,
    IonNote,
    Modal,
    AllowAccess,
    IonModal,
    IonSelect,
    IonSelectOption,
  },
  setup() {
    // 位置情報設定用のダイアログ表示制御
    const isOpenRef = ref(false);
    const isOpenSettingRef = ref(false);
    const setOpen = (state) => isOpenRef.value = state;
    const setSettingOpen = (state) => isOpenSettingRef.value = state;
    const secchiMsg = '設置手続きを続けるには位置情報へのアクセスを許可する必要があります。';
    return { isOpenRef, isOpenSettingRef, setOpen, setSettingOpen, secchiMsg }
    
  },
  data(){
      return {
        status:'',
        tokuiName:'',
        genbaName:'',
        address:'',
        contCd1:'',
        contCd2:'',
        startDate:'',
        bikoSecchi:'',
        bikoTekkyo:'',
        lat:'',
        lng:'',
        rentNo:'',
        errFlg:false,

        loadingFlg: false,
        notBarrageFlg:false,

        //拠点リスト用
        kyotenList: [],
        kyotenSel: {kyoten_cd: '', kyoten_name: ''},
        kyotenNowhere: {kyoten_cd: '', kyoten_name: ''},

        //設定方法 0:iphone 1:android
        settingType: 0,
      };
  },
  computed: {
    title: function () {
      let name = '';
      if(this.$store.state.procType == this.$store.state.SECCHI){
        name = '設置';
      }else if(this.$store.state.procType == this.$store.state.TEKKYO){
        name = '撤去';
      }else if(this.$store.state.procType == this.$store.state.KOKAN){
        if(this.$store.state.irekaeMode){
          name = '入替';
        }else{
          name = '交換';
        }
      }else if(this.$store.state.procType == this.$store.state.KAKUNIN){
        name = '確認';
      }

      return name;
    },
    contCdMissingFlg: function () {
      let result = false;
      if(this.getStatusName(this.status) != '未設置' && this.contCd1 == '') {
        result = true;
      }
      return result;
    }
  },
  async ionViewDidLeave() {
    this.status = '';
    this.tokuiName = '';
    this.genbaName = '';
    this.address = '';
    this.contCd1 = '';
    this.contCd2 = '';
    this.startDate = '';
    this.bikoSecchi = '';
    this.bikoTekkyo = '';
    this.lat = '';
    this.lng = '';
    this.rentNo = '';
    this.errFlg = false;
  },
  async ionViewDidEnter() {
    // ステータスクリア
    this.status = "";
    this.kyotenSel.kyoten_cd = "";
    this.kyotenSel.kyoten_name = "";

    // ストアからコンテナCD取得
    this.contCd1 = this.$store.state.contCd1;
    this.contCd2 = this.$store.state.contCd2;
    if(this.$store.state.irekaeMode){
      // 入替処理の場合
      this.contCd2 = this.contCd1;
    } else if(this.$store.state.procType == this.$store.state.KOKAN){
      // 交換処理の場合、交換前コンテナと交換後コンテナを確認する
      const postData = {
        cont_cd1: this.contCd1,
        cont_cd2: this.contCd2,
      }
      await this.axios.post('mobile/dist-cont', postData).then( res => {
        this.contCd1 = res.data.cont_cd1;
        this.contCd2 = res.data.cont_cd2;
        this.$store.commit('setContCd', { name: 'contCd1', contCd: this.contCd1 });
        this.$store.commit('setContCd', { name: 'contCd2', contCd: this.contCd2 });
      })
    }

    // ここで this.coontCd1 が空の場合、エラーメッセージを表示し、ページを戻る
    if (this.contCd1 == '') {
      
        const alert = await alertController.create({
          header: 'エラー', // アラートのヘッダー
          message: "コンテナ番号が入力されていません。<br/>再度コンテナ番号を入力してください。", // メッセージ部分
          buttons: [
            {
              text: '読取り画面へ戻る', // ボタンのテキスト
              handler: () => {
                this.$router.push('/select');
              }
            }
          ],
          backdropDismiss: false,
        });

      await alert.present();
      return;
    }

    //拠点リストの取得
    await this.getKyotenList();
    
    // 拠点情報を取得する
    const postKyotenInfo = {
      cont_cd: this.contCd1
    }
    await this.axios.post('mobile/kyoten-info', postKyotenInfo).then( res => {
      if(res.data.kyotenInfo.kyoten_cd) {
        this.kyotenSel.kyoten_cd = res.data.kyotenInfo.kyoten_cd;
        this.kyotenSel.kyoten_name = res.data.kyotenInfo.kyoten_name;
      }
    })

    if(this.$store.state.reserveMode || this.$store.state.genbaMode) {
      // 予約モードか現場選択モードの場合、ストアにセットされた貸出情報を取得
      this.tokuiName = this.$store.state.tokuiName;
      this.genbaName = this.$store.state.genbaName;
      // 拠点情報を取得する
      // const postKyotenInfo = {
      //   cont_cd: this.contCd1
      // }
      // await this.axios.post('mobile/kyoten-info', postKyotenInfo).then( res => {
      //   this.kyoten.kyoten_cd = res.data.kyotenInfo.kyoten_cd;
      //   this.kyoten.kyoten_name = res.data.kyotenInfo.kyoten_name;
      // })
    }else{
      await this.getRentInfo();
    }
  },
  methods: {
    // コンテナ情報取得
    getRentInfo: async function () {
      // 初期化
      this.notBarrageFlg=false
      await this.axios.get(`mobile/get-rentinfo/${this.contCd1}`).then(res => {
        if (res.data.result == 0) {
          this.status = res.data.status;
          this.tokuiName = res.data.tokui_name;
          this.genbaName = res.data.genba_name;
          this.address = res.data.address;
          this.startDate = res.data.start_date;
          this.bikoSecchi = res.data.biko_secchi;
          this.bikoTekkyo = res.data.biko_tekkyo;
        }
      })
    },
    // 拠点取得
    getKyotenList: function () {
      this.axios.get(`mobile/get-kyoten-list`).then(res => {
        if (res.data.result == 0) {
          this.kyotenList = res.data.kyotenList;
        }
      })
    },
    // コンテナ情報更新
    clickKakutei: function () {
      this.notBarrageFlg = true;
      if (this.$store.state.procType == this.$store.state.SECCHI) {
        // 設置処理
        this.secchiUpd();
      } else if (this.$store.state.procType == this.$store.state.TEKKYO) {
        // 撤去処理
        this.tekkyoUpd();
      } else if (this.$store.state.procType == this.$store.state.KOKAN) {
        // 交換処理
        this.kokanUpd();
      }
      this.notBarrageFlg = false;
    },
    secchiUpd: async function () {
      this.loadingFlg = true;
      await navigator.geolocation.getCurrentPosition(this.doSecchi, this.err);
    },
    doSecchi: async function (position) {
      const postData = {
        tokui_cd: this.$store.state.tokuiCd,
        tokui_name: this.tokuiName,
        genba_cd: this.$store.state.genbaCd,
        genba_name: this.genbaName,
        kyoten_cd: this.kyotenSel.kyoten_cd,
        cont_cd: this.contCd1,
        biko_secchi: this.bikoSecchi,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        reserve_no: this.$store.state.reserveNo,
      }

      await this.axios.post('mobile/secchi', postData).then(res => {
        this.loadingFlg = false;
        if (res.data.result === 0) {
          // メール送信
          this.axios.post('mail', { mobaile_type: 0, rent_no: res.data.rent_no, p_type: this.$store.state.SECCHI });

          this.$store.commit('setRentNo', { rentNo: res.data.rent_no });
          this.$router.push('kanryo');
        }
        else {
          this.presentAlert(res.data.msg);
        }
      })
    },
    tekkyoUpd: function () {
      this.loadingFlg = true;
      const postData = {
        cont_cd: this.contCd1,
        biko_tekkyo: this.bikoTekkyo,
        kyoten_cd: this.kyotenSel.kyoten_cd,
      }
      this.axios.post('mobile/tekkyo', postData).then(res => {
        this.loadingFlg = false;
        if (res.data.result === 0) {
          // メール送信
          this.axios.post('mail', { mobaile_type: 0, rent_no: res.data.rent_no, p_type: this.$store.state.TEKKYO });

          this.$store.commit('setRentNo', { rentNo: res.data.rent_no });
          this.$router.push('kanryo');
        } else {
          this.presentAlert(res.data.msg);
        }
      })
    },
    kokanUpd: async function () {
      this.loadingFlg = true;
      await navigator.geolocation.getCurrentPosition(this.doKokan, this.err);
    },
    doKokan: function (position) {
      const postData = {
        cont_cd1: this.contCd1,
        cont_cd2: this.contCd2,
        kyoten_cd: this.kyotenSel.kyoten_cd,
        biko_secchi: this.bikoSecchi,
        biko_tekkyo: this.bikoTekkyo,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      this.axios.post('mobile/kokan', postData).then(res => {
        this.loadingFlg = false;
        if (res.data.result === 0) {
          // メール送信
          this.axios.post('mail', { mobaile_type: 0, rent_no: res.data.rent_no, p_type: this.$store.state.KOKAN });

          this.$store.commit('setRentNo', { rentNo: res.data.rent_no });
          this.$router.push('kanryo');
        } else {
          this.presentAlert(res.data.msg);
        }
      })
    },
    /**
     * 拠点更新
     */
    async clickKyotenUpdate() {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: '確認',
          message: '拠点を更新してもよろしいですか？',
          buttons: [
            {
              text: 'キャンセル',
              role: 'cancel',
              cssClass: 'secondary',
              id: 'cancel-button',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              },
            },
            {
              text: 'OK',
              id: 'confirm-button',
              handler: () => {
                this.notBarrageFlg=!this.notBarrageFlg;
                this.updateKyoten();
              },
            },
          ],
        });
      return alert.present();
    },
    updateKyoten: function () {
      const param = {
        kyoten_cd: this.kyotenSel.kyoten_cd,
        cont_cd: this.contCd1
      };
      this.axios.post('mobile/kyoten-update', param).then(res => {
        if (res.data.result === 0) {
          this.$router.push("/select")
        }
      });
    },
    // 戻る
    backPage: function () {
      this.$router.back();
    },
    err: function () {
      this.setOpen(true);
    },
    // 位置情報設定ダイアログを閉じる
    closeModal: function () {
      modalController.dismiss();
    },
    // 位置情報設定ダイアログ表示
    showSetting: function (val) {
      this.$nextTick(() => {
        this.settingType = val;
        this.setSettingOpen(true);
      })
    },
    // エラーアラート表示
    presentAlert: async function (msg) {
      const alert = await alertController.create({
        header: 'エラー',
        message: msg,
        buttons: [
          { 
            text: '閉じる',
          }
        ],
        backdropDismiss: false,
      });

      await alert.present();
    },
  }
});


</script>

<style scoped>
#kakutei {
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
}
#kakutei-back {
    margin: 20px;
}
#title {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
#tokui-text {
    font-size: 20px;
    text-decoration: underline;
    color: rgb(25, 148, 170);
}
.setting {
    cursor: pointer;
}
.inp-style {
    border: solid 0.5px;
    border-color: #ddd;
    border-radius: 5px;
    word-break: break-all;
}
.disp-block {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>