<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <!-- <div v-show="errorType == 1" style="margin-top:70px">
          <p>
            端末を登録する必要があります。
          </p>
      </div>
      <div v-show="errorType == 2" class="err-area" style="margin-top:70px;">
          <div class="err-title">エラー</div>
          <div class="err-content">有効期限が切れています。<br />再度管理画面よりメールアドレスをご登録下さい。</div>
      </div> -->
      <div v-show="errorType == 2">
      <ion-card style="margin-top:70px; padding:15px">
        <ion-card-title style="font-size: 30px; margin-bottom:20px;">Login</ion-card-title>
        <div class="form">
          <div class="inp-field">
                <ion-icon :icon="phonePortraitOutline"></ion-icon>
                <ion-input v-model="tel" maxlength="20" placeholder="電話番号" class="" type="tel"></ion-input>
          </div>
          <div class="inp-field">
                <ion-icon :icon="lockClosedOutline"></ion-icon>
                <ion-input v-model="autheCd" maxlength="20" placeholder="認証コード" class="" type="email"></ion-input>
          </div>
        </div>
        <ion-button @click="login" expand="block">ログイン</ion-button>
        <div style="display:flex; justify-content:center;">
          <a @click="clickForget">認証コードを忘れた場合はこちら</a>
        </div>
      </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCard, IonCardTitle, IonIcon, IonButton, IonInput, alertController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { lockClosedOutline, phonePortraitOutline } from 'ionicons/icons';
//import axios from 'axios';

export default defineComponent({
  name: 'Login',
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardTitle,
    IonIcon,
    IonButton,
    IonInput,
  },
  setup(){
    return { lockClosedOutline, phonePortraitOutline }
  },
  data(){
      return {
        tel:'',
        autheCd:'',
        errorType: null,
      };
  },
  ionViewDidEnter(){
     // 認証処理
    var deviceCd = localStorage.getItem('device_cd');
    var accessDate = this.get_now_ymd();
    this.axios.post('mobile-login', {device_cd: deviceCd, access_date: accessDate}).then(res => { 
       if(res.data.result == 0) {
         this.$router.push("/menu");
       }
    });
  },
  mounted(){
    // ローカルストレージからデバイスコード取得
    var deviceCd = localStorage.getItem('device_cd');

    // pwaの場合URLのパラメータからデバイスコード取得
    if(deviceCd === null || deviceCd === '') {
      deviceCd = this.$route.query.devicecd;
      localStorage.setItem('device_cd', deviceCd)
    }

    // manifest.jsonの作成
    this.setManifestURL(deviceCd);

    // 認証処理
    this.axios.post('mobile-login', {device_cd: deviceCd}).then(res => { 
       if(res.data.result == 0) {
         this.$router.push("/menu");
       }else{
         this.errorType = 2;
       }
      }).catch(() => {
        this.errorType = 2;
      })
  },
  methods:{
      login: function () {
        const postData = {
          tel: this.tel,
          authe_cd: this.autheCd,
        }
        this.axios.post('mobile/login-authecd', postData).then( res => {
          if(res.data.result === 0){
            localStorage.setItem('device_cd', res.data.device_cd);
            this.$router.push('/menu');
          }else{
            this.presentAlert('入力内容に誤りがあります。')
          }
        })
      },
      presentAlert: async function (msg) {
        const alert = await alertController.create({
          header: 'エラー',
          message: msg,
          buttons: ['閉じる']
        });

        await alert.present();
      },
      // 現在時刻取得
      get_now_ymd: function () {
        var dt = new Date();
        var y = dt.getFullYear();
        var m = ("00" + (dt.getMonth()+1)).slice(-2);
        var d = ("00" + dt.getDate()).slice(-2);
        var result = y + "/" + m + "/" + d;
        return result;
      },
      // pwa用manifestの作成
      setManifestURL: function (token) {
        var baseUrl = 'https://' + document.domain;
        var startUrl = token ? baseUrl + '?devicecd=' + token : baseUrl;

        var manifest = {
          "name": "Web-Container",
          "short_name": "モバイル担当者システム",
          "icons": [
              {
                  "src": baseUrl + "/img/icons/sms-icon-bgw-192x192.png",
                  "sizes": "192x192",
                  "type": "image/png"
              },
              {
                  "src": baseUrl + "/img/icons/sms-icon-bgw-512x512.png",
                  "sizes": "512x512",
                  "type": "image/png"
              },
              {
                  "src": baseUrl + "/img/icons/sms-icon-bgw-192x192.png",
                  "sizes": "192x192",
                  "type": "image/png",
                  "purpose": "maskable"
              },
              {
                  "src": baseUrl + "/img/icons/sms-icon-bgw-512x512.png",
                  "sizes": "512x512",
                  "type": "image/png",
                  "purpose": "maskable"
              }
          ],
          "start_url": startUrl,
          "display": "standalone",
          "background_color": "#000000",
          "theme_color": "#4DBA87"
        }

        const stringManifest = JSON.stringify(manifest);
        const blob = new Blob([stringManifest], { type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#my-manifest').setAttribute('href', manifestURL);
    },
    clickForget: function () {
      this.$router.push('/resend');
    },
  }
});
</script>

<style scoped>
.toroku {
  margin:20px;
}
.err-area{
  border: 4px solid ;
  border-radius: 10px;
  background: #fbe5d6;
  margin: 0 50px;
  padding: 20px 0;
  text-align: center;
  color: #fe3532;
}
.err-title{
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}
.err-content{
  font-size: 15px;
  padding: 0 10px;
}
.inp-style {
  border: solid 0.5px;
  border-color: #ddd;
  border-radius: 5px;
}
.inp-field {
  display:flex;
  align-items:center;
}
.form {
  margin:20px 0px 20px 10px;
}
</style>