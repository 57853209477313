<template>
  <ion-header>
    <div class="allow-title">
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()"><ion-icon name="close"></ion-icon></ion-button>
        </ion-buttons>
        <!-- <div class="f-right"><ion-button @click="closeModal" :class="['close-button']">閉じる</ion-button></div> -->
      </ion-toolbar>
    </div>
  </ion-header>
  <ion-content class="ion-padding">
  <Carousel @next="next" @prev="prev" :prevHide="prevHide" :nextHide="nextHide">
		<CarouselSlide v-for="(list, index) in setting" :key="list.val" :index="index" :visibleSlide="visibleSlide">
      <dl class="setting-area">
        <dt class="setting-title">{{ list.title }}</dt>
        <dd class="setting-image"><img :src="list.image" width="200" height="340"></dd>
        <dd class="setting-content">{{ list.content }}</dd>
      </dl>
    </CarouselSlide>
  </Carousel>
  </ion-content>
</template>

<style>

.allow-title
  ion-toolbar{
    height:50px;
  }
  ion-title{
    padding:0 20px 5px;
    font-size:18px;
  }
  ion-button{
    padding:0 0 5px;
  }
.close-button {
	background: gray !important;
	margin-right: 5px;
}
.my-setting-class .modal-wrapper {
  width: 84%;
  height: 96%;
  position:absolute;
  top:2%;
  display:block;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-color: black;
  border: solid;
}
.setting-area{
  margin:0;
}
.setting-area .setting-title{
  margin:-10px auto 5px;
  text-align:center;
  font-size:17px;
}
.setting-area .setting-image{
  width:210px;
  height:350px;
  border:1px solid #000;
  margin:0 auto 10px;
  padding:4px;
}
.setting-area .setting-content{
  margin:0 auto 20px;
  text-align:center;
  font-size:12px;
}


</style>
<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonButtons, IonIcon } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import Carousel from '../components/Carousel.vue'
import CarouselSlide from '../components/CarouselSlide.vue'

export default defineComponent({
  name: 'AllowAccess',
  props: {
    title: { type: String, default: '位置情報利用設定' },
    settingType: { type: Number, default: 0 },
    close: { type: Function },
  },
  setup(props) {
    // Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.

    //0: iphone 1: android 表示
    let setting = [];

    if(props.settingType == 0){
      setting = [
        { val: 1, title: "設定方法１", image: "assets/img/setting_iphone1.png", content: "アドレスバー横のメニューを押下" },
        { val: 2, title: "設定方法２", image: "assets/img/setting_iphone2.png", content: "「Webサイトの設定」を押下" },
        { val: 3, title: "設定方法３", image: "assets/img/setting_iphone3.png", content: "「位置情報」を押下" },
        { val: 4, title: "設定方法４", image: "assets/img/setting_iphone4.png", content: "「許可」を押下" },
      ];
    }else{
      setting = [
        { val: 1, title: "設定方法１", image: "assets/img/setting_android1.png", content: "「︙」を押下" },
        { val: 2, title: "設定方法２", image: "assets/img/setting_android2.png", content: "「設定」を押下" },
        { val: 3, title: "設定方法３", image: "assets/img/setting_android3.png", content: "「サイトの設定」を押下" },
        { val: 4, title: "設定方法４", image: "assets/img/setting_android4.png", content: "「位置情報」を押下" },
        { val: 5, title: "設定方法５", image: "assets/img/setting_android5.png", content: "「位置情報」をオン" },
        { val: 6, title: "設定方法６", image: "assets/img/setting_android6.png", content: "ブロック中の当ページを押下" },
        { val: 7, title: "設定方法７", image: "assets/img/setting_android7.png", content: "「許可」を押下" },
      ];
    }

    // indexとイコールの変数を作成する
    const visibleSlide = ref(0);
    const prevHide = ref(true);
    const nextHide = ref(false);

    const slidesLength = computed(() => setting.length);

    // Carouselから受け取ったnext
    const next = () => {
      if(visibleSlide.value == slidesLength.value - 2) {
        nextHide.value = true;
        visibleSlide.value++
      } else {
        prevHide.value = false;
        visibleSlide.value++
      }
    }
    // Carouselから受け取ったprev
    const prev = () => {
      if(visibleSlide.value == 1) {
        prevHide.value = true;
        visibleSlide.value--
      } else {
        nextHide.value = false;
        visibleSlide.value--
      }
    }

    return {
      setting,
      visibleSlide,
      next,
      prev,
      nextHide,
      prevHide,
    }
      
  },
  mounted(){
  },
  data() {
    return {
    };
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonButtons, IonIcon, Carousel, CarouselSlide },
  emits: ['close-modal'],
  methods: {
    closeModal: function () {
      this.$emit('close-modal');
    },
  }
});
</script>