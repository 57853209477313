<template>
 <ion-content class="wrap-container" style="z-index:200">
  <ion-header>
    <ion-toolbar>
	<ion-title>{{ title }}</ion-title>
	<ion-buttons slot="end">
		<ion-button @click="closeModal()"><ion-icon name="close"></ion-icon></ion-button>
	</ion-buttons>
	<!-- <div class="f-right"><ion-button @click="closeModal" :class="['close-button']">閉じる</ion-button></div> -->
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
	<div>
    {{ msg }}
	</div>
  <div class="show-setting-title">設定方法は下記をご参照ください</div>
	<div class="show-setting-type"><a @click="showSetting(0)">iPhoneをお使いの方</a></div>
	<div class="show-setting-type"><a @click="showSetting(1)">Androidをお使いの方</a></div>
  </ion-content>
</ion-content>
</template>

<style>
.wrap-container {
    border: 2px solid black;
    border-radius: 15px;
    overflow: hidden;
}

.show-setting-title {
    text-align: center;
    margin: 30px 0 10px;
    font-size: 18px;
    font-weight: bold;
}
.show-setting-type {
    cursor: pointer;
    text-align: center;
    margin-bottom: 4px;
}
.close-button {
    background: gray !important;
    margin-right: 5px;
}
.my-custom-class .modal-wrapper {
    width: 90%;
    height: 280px;
    position: absolute;
    top: 5%;
    display: block;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}
</style>
<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonButtons, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    title: { type: String, default: '警告' },
    msg: { type: String, default: 'msg' },
  },
  data() {
    return {
      content: '設置手続きを続けるには位置情報へのアクセスを許可する必要があります。',
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonButtons, IonIcon },
  emits: ['show-setting','close-modal'],
  methods: {
    showSetting: function (val) {
      this.$emit('show-setting',val);
    },
    closeModal: function () {
      this.$emit('close-modal');
    }
  }
});
</script>