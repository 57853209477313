<template>
  <ion-page  v-show="isReadyShow">
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header v-if="$store.state.irekaeMode" color="new">
            <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
        <ion-card-header v-else color="secondary">
            <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div id="video-input">
              <video id="video" autoplay playsinline width="300" height="300"></video>
              <canvas id="canvas" width="300" height="300"></canvas>
          </div>
         <ion-button id="back" color="warning" @click="backPage">戻る</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCardTitle, IonCard, IonCardContent, IonButton, alertController } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Readqr',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonCardContent,
    IonButton,
  },
  data(){
      return {
        url:'',
        player: '',
        canvas: '',
        errMsg: '',
        scanFlg: false,
        isReadyShow: true
      };
  },
  //ブラウザの戻るボタンを検知
  created() {
    window.addEventListener('popstate', this.handlePop);
  },
  //popstateを破棄
  beforeUnmount() {
    
    window.removeEventListener('popstate', this.handlePop);
  },
  computed: {
    title: function () {
      let name = '';
      if(this.$store.state.procType == this.$store.state.SECCHI){
        name = '設置';
      }else if(this.$store.state.procType == this.$store.state.TEKKYO){
        name = '撤去';
      }else if(this.$store.state.procType == this.$store.state.KOKAN){
        if(this.$store.state.irekaeMode){
          name = '入替';
        }else{
          name = '交換';
        }
      }else if(this.$store.state.procType == this.$store.state.KAKUNIN){
        name = '確認';
      }else if(this.$store.state.procType == this.$store.state.PHOTOS){
        name = '写真';
      }

      return name;
    }
  },
  ionViewDidEnter() {
    this.$nextTick(() => {
      this.isReadyShow = true;
    })
      this.initVideo();
  },
  ionViewDidLeave(){
    this.isReadyShow = false;
    // カメラを停止
    this.stopScan();
  },
  methods: {
 stopScan() {
    // カメラのストリームを取得している player オブジェクトがあるか確認
    if (this.player && this.player.srcObject) {
      // player に設定されているメディアストリームを取得
      const stream = this.player.srcObject;
      // ストリームの各トラックを取得し、それぞれを停止
      const tracks = stream.getTracks();
      tracks.forEach(track => {
        track.stop();
      });
      // player の srcObject を null に設定して参照を解除
      this.player.srcObject = null;
    }
  },
    handlePop: function (event) {
      //form の from to は？
      if (event.state && this.$store.state.procType !== this.$store.state.KOKAN) {
        if ((event.state.forward === "/genbalist" || event.state.forward === "/kakutei") && event.state.current === "/readqr") {
          this.$router.push('Menu');
        }
      }
    },
    // カメラ画面を起動
    initVideo: function () {
      this.scanFlg = false;
      this.player = document.getElementById('video');
      this.canvas = document.getElementById('canvas');
      
      if (navigator.mediaDevices) {
          navigator.mediaDevices.getUserMedia({
              audio: false,
              video: {
                width: 300,
                height: 300,
                facingMode: "environment"  
              }
          })
              .then(this.handleSuccess)
              .catch(function () {
                  alert('ビデオカメラを使用できません');
              });
      } else {
          alert('ビデオカメラを使用できません');
      }
    },
    startScan: function (callback) {
      let self = this;
      const width = this.canvas.width;
      const height = this.canvas.height;
      var canvasContext = this.canvas.getContext('2d');

      var intervalHandler = setInterval(async function () {
          if(canvasContext!=null){
            canvasContext.drawImage(self.player, 0, 0, width, height);
            var imageData = canvasContext.getImageData(0, 0, width, height);
            var scanResult = window.jsQR(imageData.data, imageData.width, imageData.height);
          }
          if (scanResult) {
              clearInterval(intervalHandler);
              await callback(scanResult);
              self.drawLine(canvasContext, scanResult.location);

              // video と canvas を入れ替え
              self.canvas.style.display = 'block';
              self.player.style.display = 'none';
              self.player.pause();

              self.player.srcObject.getTracks().forEach(function(track) {
                track.stop();
              });
          }
      }, 200);
    },
    handleSuccess: function (stream) {

        this.player.srcObject = stream;

      this.startScan((scanResult) => {
          if(this.isReadyShow==false){
            return;
          }
          // スキャン済みの場合処理を中断
          if(this.scanFlg)return;
          this.scanFlg = true;

          // カメラの停止
          if(this.player.srcObject!==null){
            this.player.pause();
            this.player.srcObject.getTracks().forEach(function(track) {
              track.stop();
            });
          }

          if(this.$store.state.procType == this.$store.state.KOKAN && !this.$store.state.irekaeMode && this.$store.state.contCd2 == '') {
            return this.chkContByCdFirst(scanResult);
          }else{
            // 貸出情報との整合性を確認
            const postData = {
              p_mid: JSON.parse(scanResult.data).m,
              cont_cd1: JSON.parse(scanResult.data).c,
              cont_cd2: this.$store.state.contCd2,
              p_type: this.$store.state.procType,
              rsv_mode: this.$store.state.reserveMode,
              i_mode: this.$store.state.irekaeMode,
            }

            this.axios.post('mobile/check-cont', postData).then( res => {
              if(res.data.result === 0) {

                this.$store.commit('setContCd', { name: 'contCd1', contCd: JSON.parse(scanResult.data).c });

                if(this.$store.state.procType == this.$store.state.PHOTOS){
                  // 写真画面に遷移
                  this.$router.push('photos');
                }else if(res.data.genba_select){
                  // 現場選択画面に遷移
                  this.$store.commit('readQrMode', {getFlg:false})
                  this.$router.push('genbalist');
                }else{
                  // コンテナ関連処理画面に遷移
                  this.$router.push('kakutei');
                }
              }else{
                this.errMsg = res.data.msg;
                this.presentAlert();
                return;
              }
            })
          }
        });
    },
    // 交換時1台目の場合、貸出情報との整合性を確認
    chkContByCdFirst: async function (scanResult) {
      const postData = {
        p_mid: JSON.parse(scanResult.data).m,
        cont_cd: JSON.parse(scanResult.data).c,
      }

      await this.axios.post('mobile/check-dispos', postData).then( res => {
        if(res.data.result === 0) {
          this.$store.commit('setContCd', { name: 'contCd2', contCd: JSON.parse(scanResult.data).c })
          return this.$router.push('select');
        }else{
          this.errMsg = res.data.msg;
          this.presentAlert();
          return;
        }
      })
    },
    drawLine: function (ctx, pos, options={color:"blue", size:5}) {
      // 線のスタイル設定
      ctx.strokeStyle = options.color;
      ctx.lineWidth   = options.size;

      // 線を描く
      ctx.beginPath();
      ctx.moveTo(pos.topLeftCorner.x, pos.topLeftCorner.y);         // 左上からスタート
      ctx.lineTo(pos.topRightCorner.x, pos.topRightCorner.y);       // 右上
      ctx.lineTo(pos.bottomRightCorner.x, pos.bottomRightCorner.y); // 右下
      ctx.lineTo(pos.bottomLeftCorner.x, pos.bottomLeftCorner.y);   // 左下
      ctx.lineTo(pos.topLeftCorner.x, pos.topLeftCorner.y);         // 左上に戻る
      ctx.stroke();
    },
    // エラーアラート表示
    presentAlert: async function () {
      const alert = await alertController.create({
        header: 'エラー',
        message: this.errMsg,
        buttons: [
          { 
            text: '閉じる',
            handler: () => {
              this.initVideo();
            }
          }
        ],
        backdropDismiss: false,
      });

      await alert.present();
    },
    // 戻る
    backPage: function(){
      if(this.player.srcObject!==null){
        this.player.pause();
        this.player.srcObject.getTracks().forEach(function(track) {
          track.stop();
        });
      }
      this.$router.push('select');
    },
  },
});
</script>

<style scoped>
#video,
#canvas {
    justify-content: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}
#canvas {
    display: none;
}
#qr {
    margin-left: 10px;
}
.tokui {
    margin-left: 20px;
}
#next,
#contnext {
    margin-left: 20px;
    margin-right: 20px;
}
#back,
#contback {
    margin: 20px;
}
#title {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.tokui-text {
    font-size: 20px;
    text-decoration: underline;
    color: rgb(25, 148, 170);
}
.conte {
    margin: 20px;
}
.inp-cont {
    border: solid 0.5px;
    border-color: #ddd;
    border-radius: 5px;
}
</style>