<template>
  <ion-app>
    <ion-header class="ion-text-center header-title">
      <ion-toolbar color="light">
        <ion-title class="text-h4 web-container-title">
        <img src="../public/img/sms-title.png" width="220" heght="27" />&nbsp;</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, IonHeader, IonToolbar, IonTitle } from '@ionic/vue';
import { defineComponent } from 'vue';
import { Storage } from '@ionic/storage';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
     IonHeader,
     IonToolbar,
     IonTitle,
  },
  mounted(){
   this.init()
  },
  methods:{
    async init() {
      const storage = new Storage();
      await storage.create();
      await storage.set('name', 'teso');
    }
  }
});
</script>
<style>
@import './css/style.css'
</style>