<template>
    <div v-show="visibleSlide === index" class="carousel-slide">
      <slot></slot>
    </div>
</template>

<style>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Carousel',
  props: {
    visibleSlide: { type: Number, default: 0 },
    index: { type: Number, default: 0 },
  },
  setup() {

  },
  mounted(){
  },
  data() {
    return {
    };
  },
});
</script>