<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">端末登録</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <!-- List Inset Lines -->
          <ion-item class="ion-text-center" lines="none">登録を完了するために以下の項目をご入力後、登録ボタンをクリックして下さい。</ion-item>
          <div class="toroku-item">
            <div>
              <ion-label position="floating">氏名</ion-label>
              <ion-input class="inp-style" type="name" maxlength="40" v-model="tantouName" :class="{'inp-error':isError(err.tantouName)}"></ion-input>
            </div>
            <div v-show="isError(err.tantouName)">
              <span v-html="dispError(err.tantouName)"></span>
            </div>
          </div>
          <div class="toroku-item">
            <div>
              <ion-label position="floating">電話番号</ion-label>
              <ion-input class="inp-style" type="tel" v-model="tel" maxlength="15" :class="{'inp-error':isError(err.tel)}"></ion-input>
            </div>
            <div v-show="isError(err.tel)">
              <span v-html="dispError(err.tel)"></span>
            </div>
          </div>
          <div class="toroku-item">
            <div>
              <ion-label position="floating">認証コード</ion-label>
              <ion-input class="inp-style" type="email" v-model="autheCd" maxlength="20" :class="{'inp-error':isError(err.autheCd)}"></ion-input>
            </div>
            <div v-show="isError(err.autheCd)">
              <span v-html="dispError(err.autheCd)"></span>
            </div>
          </div>
          <ion-button id="kakutei" color="primary" expand="block" @click="clickShonin">登録</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
    IonContent,
    IonCardHeader,
    IonCardContent,
    IonLabel,
    IonPage,
    IonCardTitle,
    IonCard,
    IonButton,
    IonItem,
    IonInput,
    alertController,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "Shonin",
    components: {
        IonContent,
        IonCardHeader,
        IonCardContent,
        IonLabel,
        IonPage,
        IonCardTitle,
        IonCard,
        IonButton,
        IonItem,
        IonInput,
    },
    data() {
        return {
            device_cd: "",
            tantouName: "",
            tel: "",
            autheCd: "",
            err: {
                tantouName: "",
                tel: "",
                autheCd: "",
            },
        };
    },
    mounted() {
        // urlからデバイスコードを取得
        // this.deviceCd = this.$route.query.devicecd;
        // // 認証処理
        // this.axios.post('mobile-login', {device_cd: this.deviceCd}).then(res => {
        //   if(res.data.result == 9) {
        //     this.$router.push("/login");
        //   }
        //   this.tantouName = res.data.tantou_name;
        //   this.tel = res.data.tel;
        // });
    },
    methods: {
        clickShonin: function () {
            if (this.inputCheck()) {
                // this.presentAlert("入力内容に誤りがあります。")
                return;
            }

            // 登録処理
            this.axios
                .post("token-approval", {
                    tantou_name: this.tantouName,
                    tel: this.tel,
                    authe_cd: this.autheCd,
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.result === 0) {
                        localStorage.setItem("device_cd", res.data.device_cd);
                        this.$router.push("/kakunin");
                    } else {
                        this.presentAlert(res.data.msg);
                    }
                });
        },
        // pwa用のmanifestを生成
        setManifestURL: function (token) {
            var baseUrl = "https://" + document.domain;
            var startUrl = token ? baseUrl + "?devicecd=" + token : baseUrl;

            var manifest = {
                name: "Web-Container",
                short_name: "モバイル担当者システム",
                icons: [
                    {
                        src: baseUrl + "/img/icons/sms-icon-bgw-192x192.png",
                        sizes: "192x192",
                        type: "image/png",
                    },
                    {
                        src: baseUrl + "/img/icons/sms-icon-bgw-512x512.png",
                        sizes: "512x512",
                        type: "image/png",
                    },
                    {
                        src: baseUrl + "/img/icons/sms-icon-bgw-192x192.png",
                        sizes: "192x192",
                        type: "image/png",
                        purpose: "maskable",
                    },
                    {
                        src: baseUrl + "/img/icons/sms-icon-bgw-512x512.png",
                        sizes: "512x512",
                        type: "image/png",
                        purpose: "maskable",
                    },
                ],
                start_url: startUrl,
                display: "standalone",
                background_color: "#000000",
                theme_color: "#4DBA87",
            };

            const stringManifest = JSON.stringify(manifest);
            const blob = new Blob([stringManifest], {
                type: "application/json",
            });
            const manifestURL = URL.createObjectURL(blob);
            document
                .querySelector("#my-manifest")
                .setAttribute("href", manifestURL);
        },
        inputCheck() {
            this.clearError();
            if (!this.tantouName) {
                this.err.tantouName = ["氏名を入力してください。"];
            }

            if (!this.tel) {
                this.err.tel = ["電話番号を入力してください。"];
            } else {
                if (!this.isTelNoHyphen(this.tel)) {
                    this.err.tel = ["ハイフンなしで入力してください。"];
                } else {
                    if (!this.isTelNo(this.tel)) {
                        this.err.tel = ["電話番号の形式に誤りがあります。"];
                    }
                }
            }

            if (!this.autheCd) {
                this.err.autheCd = ["認証コードを入力してください。"];
            }

            //エラーがある場合、アラート
            for (let key in this.err) {
                if (this.err[key].length > 0) {
                    return true;
                }
            }

            return false;
        },
        clearError() {
            for (let key in this.err) {
                this.err[key] = [];
            }
        },
        presentAlert: async function (msg) {
            const alert = await alertController.create({
                header: "エラー",
                message: msg,
                buttons: [
                    {
                        text: "ログインする",
                        role: "confirm",
                        handler: () => {
                            this.$router.push("/login");
                        },
                    },
                    {
                        text: "閉じる",
                        role: "cancel",
                        // handler: () => {
                        //   this.handlerMessage = 'Alert canceled';
                        // },
                    },
                ],
            });

            await alert.present();
        },
    },
});
</script>

<style scoped>
#kakutei {
    margin: 20px;
}
#back {
    margin: 20px;
}
#title {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
#tokui-text {
    font-size: 20px;
    text-decoration: underline;
    color: rgb(25, 148, 170);
}
.toroku-item {
    margin-bottom: 20px;
}
.inp-style {
    border: solid 0.5px;
    border-color: #ddd;
    border-radius: 5px;
}
</style>