<template>
  <div class="carousel">
    <button :class="{'carousel__prev_none':prevHide}" @click="prev" class="carousel__prev">&#12296;</button>
    <slot></slot>
    <button :class="{'carousel__next_none':nextHide}" @click="next" class="carousel__next">&#12297;</button>
  </div>
</template>

<style>
.carousel{
  display:flex;
  justify-content: space-around;
  align-items: center;
}
.carousel .carousel__prev{
  margin:0 5px;
  background-color: transparent;
  font-size:30px;
}
.carousel .carousel__next{
  margin:0 5px;
  background-color: transparent;
  font-size:30px;
}
.carousel .carousel__prev_none{
  visibility:hidden;
}
.carousel .carousel__next_none{
  visibility:hidden;
}
/* ボタンのクリック領域 */
.carousel button{
  width:100%;
  height:50px;
  margin:180px 0;
}
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Carousel',
  props: {
    prevHide: { type: Boolean, default: true },
    nextHide: { type: Boolean, default: false },
  },
  setup(props, context) {
    const next = () => {
      context.emit('next')
    }
    const prev = () => {
      context.emit('prev')
    }

    return {
      next,
      prev,
    }
  },
  mounted(){
  },
  data() {
    return {
    };
  },
});
</script>