import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
  state: {
	// 処理タイプ
	procType: '0',

	// 処理タイプ別定数
	SECCHI: '0',
	TEKKYO: '1',
	KOKAN: '2',
	KAKUNIN: '3',
	PHOTOS: '4',
	IREKAE: '5',

	// コンテナコード
	contCd1: '',
	contCd2: '',

	// 貸出情報
	rentNo: '',
	tokuiCd: '',
	tokuiName: '',
	genbaCd: '',
	genbaName: '',
	genbaAddress: '',

	// 予約情報
	reserveNo: '',
	reserveMode: false,
	rsvSearch: {},
	rsvInfo: {},

	// 現場選択モード
	genbaMode: false,

	// 設置画面から戻る場合のフラグ管理 false readQr画面,true inpcont画面
	readQrFlg:false,

	// 入替モード
	irekaeMode: false,
  },
  mutations: {
	/** コンテナCD */
	setContCd(state, payload){
		state[payload.name] = payload.contCd;
	},
	clearContCd(state){
		state.contCd1 = '';
		state.contCd2 = '';
	},
	/** 処理タイプ */
	setProcType(state, payload){
		state.procType = payload.procType;
	},
	/** 貸出番号 */
	setRentNo(state, payload){
		state.rentNo = payload.rentNo;
	},
	clearRentNo(state){
		state.rentNo = '';
	},
	/** 貸出情報 */
	setRentInfo(state, payload){
		state.tokuiCd = payload.tokuiCd;
		state.tokuiName = payload.tokuiName;
		state.genbaCd = payload.genbaCd;
		state.genbaName = payload.genbaName;
		state.genbaAddress = payload.genbaAddress;
	},
	clearRentInfo(state){
		state.tokuiName = '';
		state.genbaName = '';
		state.genbaAddress = '';
	},
	/** 予約モード */
	rsvModeOn(state){
		state.reserveMode = true;
	},
	rsvModeOff(state){
		state.reserveMode = false;
	},
	/** 予約検索条件 */
	setRsvSearch(state, payload){
		state.rsvSearch = payload.search;
	},
	clearRsvSearch(state){
		state.rsvSearch = {};
	},
	/** 予約番号 */
	setRsvNo(state, payload){
		state.reserveNo = payload.reserveNo;
	},
	clearRsvNo(state){
		state.reserveNo = '';
	},
	/** 予約詳細情報 */
	setRsvInfo(state, payload){
		state.rsvInfo = payload.rsvInfo;
	},
	clearRsvInfo(state){
		state.rsvInfo = {};
	},
	/** 現場選択モード */
	gModeOn(state){
		state.genbaMode = true;
	},
	gModeOff(state){
		state.genbaMode = false;
	},
	readQrMode(state,getFlg){
		state.readQrFlg = getFlg;
	},
	/** 入替モード */
	iModeOn(state){
		state.irekaeMode = true;
	},
	iModeOff(state){
		state.irekaeMode = false;
	},
  },
  plugins: [
	createPersistedState({
		key: 'vuex-session',
		storage: window.sessionStorage
	})
  ]
})

export default store