<template>
  <ion-page>
    <ion-content :fullscreen="true">
     <ion-card style="margin-top:70px">
        <ion-card-header color="secondary">
            <ion-card-title class="ion-text-center" size="large">MENU</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div id="container" style="margin-top: 30px;">
            <div class="menubtn">
                <span class="menu-btn" @click="clickSecchi"><img :src="btn1" width="300" height="104" alt="設置"/></span>
            </div>
            <div class="menubtn">
                <span class="menu-btn" @click="clickTekkyo"><img :src="btn2" width="300" height="104" alt="撤去"/></span>
            </div>
            <div class="menubtn">
                <span class="menu-btn" @click="clickKokan"><img :src="btn3" width="300" height="104" alt="交換"/></span>
            </div>
            <div class="menubtn">
                <span class="menu-btn" @click="clickKakunin"><img :src="btn4" width="300" height="104" alt="確認"/></span>
            </div>
            <div class="menubtn">
                <span class="menu-btn" @click="clickJokyo"><img :src="btn5" width="300" height="104" alt="状況"/></span>
            </div>
            <div class="menubtn">
                <span class="menu-btn" @click="clickPhotos"><img :src="btn8" width="300" height="104" alt="写真"/></span>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<style scoped>
  .menubtn{
    margin:20px 0;
    text-align: center;
  }

  .menu-btn {
    cursor: pointer;
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 500;
    letter-spacing: .0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143em;
    text-transform: uppercase;
    transition-duration: .28s;
    transition-property: box-shadow,transform,opacity;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    filter: drop-shadow(2.5px 2.5px 0 rgb(196, 196, 196));
  }
  .menu-btn:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  .menu-btn:active {
    filter: drop-shadow(0.5px 0.5px 0 rgb(196, 196, 196));
    transform: translate(2px, 2px) scale3d(1, 1, 1);
  }
  img{
    image-rendering: -webkit-optimize-contrast;
    -webkit-backface-visibility: hidden;
  }
</style>

<script>
import { IonContent, IonPage, IonCardTitle, IonCard, IonCardContent } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Menu',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonCardContent
  },
  data(){
      return {
        btn1: require('@/assets/img/mobile_btn1.png'),
        btn2: require('@/assets/img/mobile_btn2.png'),
        btn3: require('@/assets/img/mobile_btn3.png'),
        btn4: require('@/assets/img/mobile_btn4.png'),
        btn5: require('@/assets/img/mobile_btn5.png'),
        btn8: require('@/assets/img/mobile_btn8.png'),
      };
  },
  ionViewDidEnter(){
    // ストアの初期化
    this.$store.commit('rsvModeOff');
    this.$store.commit('gModeOff');
    this.$store.commit('iModeOff');
    this.$store.commit('clearContCd');
    this.$store.commit('clearRentNo');
  },
  methods:{
    // 設置ボタン
    clickSecchi(){
        this.$store.commit('setProcType', { procType: this.$store.state.SECCHI })
        this.$router.push('select');
    },
    // 撤去ボタン
    clickTekkyo(){
        this.$store.commit('setProcType', { procType: this.$store.state.TEKKYO })
        this.$router.push('select');
    },
    // 交換ボタン
    clickKokan(){
        this.$store.commit('setProcType', { procType: this.$store.state.KOKAN })
        this.$router.push('select-irekae');
    },
    // 確認ボタン
    clickKakunin(){
        this.$store.commit('setProcType', { procType: this.$store.state.KAKUNIN })
        this.$router.push('select');
    },
    // 状況ボタン
    clickJokyo(){
        this.$router.push("/jokyo");
    },
    // 写真ボタン
    clickPhotos(){
        this.$store.commit('setProcType', { procType: this.$store.state.PHOTOS })
        this.$router.push('select');
    },
  }
});
</script>
