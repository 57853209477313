<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header v-if="$store.state.irekaeMode" color="new">
            <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
        <ion-card-header v-else color="secondary">
            <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
      <ion-card-content>
        <div id="container" style="margin-top: 30px;">
          <div v-show="fFlg && $store.state.procType==$store.state.KOKAN && !$store.state.irekaeMode" style="margin-top:20px;margin-left:20px;"><span style="font-weight:bold; padding-top:10px;">1台目のコンテナ情報を入力してください。</span></div>
          <div v-show="!fFlg && $store.state.procType==$store.state.KOKAN && !$store.state.irekaeMode" style="margin-top:20px;margin-left:20px;"><span style="font-weight:bold">2台目のコンテナ情報を入力してください。</span></div>
          <div class="menubtn">
              <span class="menu-btn" @click="clickQr()"><img :src="btn6" width="300" height="104" alt="QRコード読み取り"/></span>
          </div>
          <div class="menubtn">
              <span class="menu-btn" @click="clickCont()"><img :src="btn7" width="300" height="104" alt="コンテナ識別番号手入力"/></span>
          </div>
          <div class="menubtn" v-if="showRsv">
              <span class="menu-btn" @click="clickReserve()"><img :src="btn9" width="300" height="104" alt="予約リスト"/></span>
          </div>
          <div class="menubtn">
              <ion-button color="warning" @click="clickBack()">戻る</ion-button>
          </div>

        </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCardTitle, IonButton, IonCard, IonCardContent } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Select',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonButton,
    IonCard,
    IonCardContent,
  },
  data(){
      return {
        fFlg: true,
        btn6: require('@/assets/img/mobile_btn6.png'),
        btn7: require('@/assets/img/mobile_btn7.png'),
        btn9: require('@/assets/img/mobile_btn9.png'),
      };
  },
  computed: {
    title: function () {
      let name = '';
      if(this.$store.state.procType == this.$store.state.SECCHI){
        name = '設置';
      }else if(this.$store.state.procType == this.$store.state.TEKKYO){
        name = '撤去';
      }else if(this.$store.state.procType == this.$store.state.KOKAN){
        if(this.$store.state.irekaeMode){
          name = '入替';
        }else{
          name = '交換';
        }
      }else if(this.$store.state.procType == this.$store.state.KAKUNIN){
        name = '確認';
      }else if(this.$store.state.procType == this.$store.state.PHOTOS){
        name = '写真';
      }

      return name;
    },
    showRsv: function () {
      if(this.$store.state.procType == this.$store.state.SECCHI){
        return true;
      }else{
        return false;
      }
    },
  },
  ionViewDidEnter(){
    // 各種モードを初期化
    this.$store.commit('rsvModeOff');
    this.$store.commit('gModeOff');
    this.$store.commit('clearRsvNo');

    // 交換処理の場合、何台目のコンテナか表示
    if(this.$store.state.contCd2 != ''){
      this.fFlg = false;
    }else{
      this.fFlg = true;
    }
  },
  methods:{
    // QRコード読取画面へ
    clickQr(){
        this.$router.push('readqr');
    },
    // コンテナ識別番号手入力画面へ
    clickCont(){
        this.$router.push('inpcont');
    },
    // 予約選択処理
    clickReserve(){
        this.$store.commit('rsvModeOn');
        this.$router.push('reserve');
    },
    // 戻るボタン
    clickBack(){
      if(this.$store.state.contCd2 !== ''){
        this.$store.commit('clearContCd');
        this.$router.back();
      // }else if(this.$store.state.procType == this.$store.state.KOKAN || this.$store.state.procType == this.$store.state.IREKAE){
      }else if(this.$store.state.procType == this.$store.state.KOKAN){
        this.$router.push('/select-irekae');
      }else{
        this.$router.push('/menu');
      }
    }
  }
});
</script>

<style scoped>
.menubtn {
    margin: 20px 0;
    text-align: center;
}

.menu-btn {
    cursor: pointer;
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 500;
    letter-spacing: 0.0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143em;
    text-transform: uppercase;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    filter: drop-shadow(2.5px 2.5px 0 rgb(196, 196, 196));
}
.menu-btn:hover {
    cursor: pointer;
    opacity: 0.8;
}
.menu-btn:active {
    filter: drop-shadow(0.5px 0.5px 0 rgb(196, 196, 196));
    transform: translate(2px, 2px) scale3d(1, 1, 1);
}
img {
    image-rendering: -webkit-optimize-contrast;
    -webkit-backface-visibility: hidden;
}
</style>