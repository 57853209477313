<template>
    <ion-page style="max-width:100%; max-height:100%;">
        <ion-content :fullscreen="true">
            <ion-card class="title" style="display: flex; flex-direction: column; height: 75vh;">
                <ion-card-header color="secondary">
                    <ion-card-title class="ion-text-center" size="xx-large">設置</ion-card-title>
                </ion-card-header>
                <ion-toolbar color="medium">
                    <ion-card-title class="toolber-font">抽出結果一覧</ion-card-title>
                </ion-toolbar>
                <p style="text-align:center; width:100%;  color:red; font-size:20px;" v-if="non_data_error!=''">{{non_data_error}}</p>
                <ion-card-content class="card-contents" fullscreen v-if="reserveInfo.length>0">
                    <ion-list
                    class="listData"
                    v-for="item in reserveInfo"
                    :key="item.reserve_no"
                    :value="item.reserve_no"
                    >
                            <div class="twoRows">
                                <div class="oneRows">
                                    <ion-item-group>
                                        <ion-item-divider style="width:100%;" color="secondary" class="driver_pos">
                                            <ion-text class="no_pos">
                                                {{item.reserve_no}}
                                            </ion-text>
                                            <ion-text>
                                                {{item.reserve_date}}
                                            </ion-text>
                                        </ion-item-divider>
                                        <div class="items_pos">
                                            <ion-item>
                                                <div class="item_pos">
                                                    <p class="item_pos_p">{{item.tokui_name}}</p>
                                                </div>
                                            </ion-item>
                                            <ion-item lines="none">
                                                <div class="item_pos">
                                                    <p class="item_pos_p">{{item.genba_name}}</p>
                                                </div>

                                            </ion-item>
                                        </div>
                                    </ion-item-group>
                                </div>
                                <ion-button class="btn_pos" expand="block" @click="clickSelect(item)">選択</ion-button>
                            </div>
                    </ion-list>
                </ion-card-content>
                <ion-row class="cardfooter">
                    <ion-col>
                        <ion-button id="kakutei-back" expand="block" color="warning" @click="clickBack()">戻る</ion-button>
                    </ion-col>
                </ion-row>
            </ion-card>
        </ion-content>
    </ion-page>
</template>
<style  scoped>
.kakutei-back {
  margin:20px;
}
.toolber-font {
  color:#ffffff;
  font-size:medium;
  text-align: center;
  text-size-adjust: 10px;
  width: 100%;
  margin: 0 auto;
}
.card-contents{
    flex:1;
    margin-left:-30px;
    margin-right:auto;
    overflow-x:hidden;
    overflow-y:scroll;
}
.listData {
    width: 105vw;
}
.twoRows {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 17vh;
}
.oneRows {
    width: 70vw;
    border: 1px solid #92949c;
    margin-right: 10px;
}
.driver_pos {
  font-size: 2.5vmax;
  width: 10vw;
  height: 3vh;
}
.no_pos {
  font-size: 2.5vmax;
  width: 20%;
}
.items_pos {
    height: 12vh;
    overflow-y: auto;
}
.items_pos ion-item {
    --min-height: 32px;
}
.item_pos {
    width:150vw;
}
.item_pos_p{
    font-size: 2.2vmax;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    /* white-space: nowrap; */
}

.btn_pos {
 width: 10vw;
 height: 70%;
}
.cardfooter{
}
</style>
<script>
import {IonContent, IonPage,IonCard,  IonCardContent, IonButton} from '@ionic/vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ReserveResult',
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardContent,
    IonButton,
  },
  data(){
      return {
        search:  {
            reserve_no:'',
            tokui_cd: '',
            genba_cd: '',
            genba_address:'',
            reserve_date:'',
        },
        reserveInfo:[],
        non_data_error:'',
      };
  },

  //初期化 ページがアクティブになった時実行、データを常に最新にする
  ionViewDidEnter(){
      this.init()
  },
  methods:{
    /*
    前の画面とDBよりデータを取得して、検索結果を表示
    */
    async init(){
        //TODO ストアに変更
        /*---------前の画面からデータ取得------------*/
        
        // this.title = this.$route.params.title
        // this.search.reserve_no=this.$route.params.reserve_no
        // this.search.tokui_cd=this.$route.params.tokui_cd
        // this.search.genba_cd=this.$route.params.genba_cd
        // this.search.genba_address=this.$route.params.genba_address
        // this.search.reserve_date=this.$route.params.reserve_date

        this.reserveInfo=[]
        this.search = this.$store.state.rsvSearch;
        
        /*--------DB接続----------*/
        //エラーメッセージ初期化
        this.non_data_error ='';

        const res = await this.axios.post('/mobile/mobileSearch',{
            reserve_no:this.search.reserve_no,
            tokui_cd:this.search.tokui_cd,
            genba_cd:this.search.genba_cd,
            genba_address:this.search.genba_address,
            reserve_date:this.search.reserve_date
        })
        //データが取得できなかった場合のエラーメッセージ
        if(res.data.resultCode != '0'){
            this.non_data_error='データが取得できませんでした。'
            
            return
        }
        this.reserveInfo=res.data.resultData
    },
    /*
    選択ボタンをクリックして画面遷移する。
    */
    async clickSelect(item){
            //TODO 遷移先(選択確認画面)に、選択結果を渡す。
            //リロードなどで保持データ消える可能性があるため、ストア使って保存
            // this.$router.push({
            //         name:'ReserveDetails',params:{
            //             title:this.title,
            //             reserve_no:item.reserve_no,
            //             tokui_cd:item.tokui_cd,
            //             genba_cd:item.genba_cd,
            //             genba_address:item.genba_address,
            //             reserve_date:item.reserve_date,

            //             //検索条件
            //             search_reserve_no:this.search.reserve_no,
            //             search_tokui_cd:this.search.tokui_cd,
            //             search_genba_cd:this.search.genba_cd,
            //             search_genba_address:this.search.genba_address,
            //             search_reserve_date:this.search.reserve_date
            //         }
            //     })

            this.$store.commit('setRsvNo', { reserveNo: item.reserve_no});
            this.$store.commit('setRsvInfo', { rsvInfo: item });
            this.$router.push('/reservedetails');
    },
    /*
    前の画面に戻る
    
    TODO 遷移先(抽出条件入力画面)に、タイトルを渡す。
    リロードなどで保持データ消える可能性があるため、ストア使って保存

    タイトルを渡す手段が他にあるなら、以下の記載に変更願います。
    this.$router.push({name: 'Reserve'});
    */
    clickBack(){
        this.$router.back();
    },
  }
})
</script>