<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <!-- <div v-show="errorType == 1" style="margin-top:70px">
          <p>
            端末を登録する必要があります。
          </p>
      </div>
      <div v-show="errorType == 2" class="err-area" style="margin-top:70px;">
          <div class="err-title">エラー</div>
          <div class="err-content">有効期限が切れています。<br />再度管理画面よりメールアドレスをご登録下さい。</div>
      </div> -->
      <ion-card style="margin-top:70px; padding:15px">
        <ion-card-title style="font-size: 20px; margin-bottom:20px;">認証コード再送付</ion-card-title>
        <div class="sofu-sentence">
          <span>モバイル端末登録の際に使用した電話番号を入力してください。</span>
          <span style="color:red">入力された電話番号に紐づくメールアドレスに認証コードをお送りします。</span>
        </div>
        <div class="inp-field">
              <ion-icon :icon="phonePortraitOutline"></ion-icon>
              <ion-input v-model="tel" maxlength="20" placeholder="電話番号" class="" type="tel"></ion-input>
        </div>
        <ion-button @click="send" expand="block">送信</ion-button>
        <ion-button color="warning" @click="back" expand="block">戻る</ion-button>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCard, IonCardTitle, IonIcon, IonButton, IonInput, alertController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { lockClosedOutline, phonePortraitOutline } from 'ionicons/icons';
//import axios from 'axios';

export default defineComponent({
  name: 'Resend',
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardTitle,
    IonIcon,
    IonButton,
    IonInput,
  },
  setup(){
    return { lockClosedOutline, phonePortraitOutline }
  },
  data(){
      return {
        tel:'',
        errorType: null,
      };
  },
  methods:{
      send: function () {
        const postData = {
          tel: this.tel,
        }
        this.axios.post('send-authe', postData).then( res => {
          if(res.data.result === 0){
            this.$router.push('resend-kanryo')
          }else{
            this.presentAlert('端末が登録されていません。')
          }
        })
      },
      presentAlert: async function (msg) {
        const alert = await alertController.create({
          header: 'エラー',
          message: msg,
          buttons: ['閉じる']
        });

        await alert.present();
      },
      // 現在時刻取得
      get_now_ymd: function () {
        var dt = new Date();
        var y = dt.getFullYear();
        var m = ("00" + (dt.getMonth()+1)).slice(-2);
        var d = ("00" + dt.getDate()).slice(-2);
        var result = y + "/" + m + "/" + d;
        return result;
      },
      // pwa用manifestの作成
      setManifestURL: function (token) {
        var baseUrl = 'https://' + document.domain;
        var startUrl = token ? baseUrl + '?devicecd=' + token : baseUrl;

        var manifest = {
          "name": "Web-Container",
          "short_name": "モバイル担当者システム",
          "icons": [
              {
                  "src": baseUrl + "/img/icons/sms-icon-bgw-192x192.png",
                  "sizes": "192x192",
                  "type": "image/png"
              },
              {
                  "src": baseUrl + "/img/icons/sms-icon-bgw-512x512.png",
                  "sizes": "512x512",
                  "type": "image/png"
              },
              {
                  "src": baseUrl + "/img/icons/sms-icon-bgw-192x192.png",
                  "sizes": "192x192",
                  "type": "image/png",
                  "purpose": "maskable"
              },
              {
                  "src": baseUrl + "/img/icons/sms-icon-bgw-512x512.png",
                  "sizes": "512x512",
                  "type": "image/png",
                  "purpose": "maskable"
              }
          ],
          "start_url": startUrl,
          "display": "standalone",
          "background_color": "#000000",
          "theme_color": "#4DBA87"
        }

        const stringManifest = JSON.stringify(manifest);
        const blob = new Blob([stringManifest], { type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#my-manifest').setAttribute('href', manifestURL);
    },
    back: function () {
      this.$router.back();
    }
  }
});
</script>

<style scoped>
.toroku {
  margin:20px;
}
.err-area{
  border: 4px solid ;
  border-radius: 10px;
  background: #fbe5d6;
  margin: 0 50px;
  padding: 20px 0;
  text-align: center;
  color: #fe3532;
}
.err-title{
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}
.err-content{
  font-size: 15px;
  padding: 0 10px;
}
.inp-style {
  border: solid 0.5px;
  border-color: #ddd;
  border-radius: 5px;
}
.inp-field {
  display:flex;
  align-items:center;
  padding-left:10px;
  padding-bottom:20px;
}
.sofu-sentence{
  padding:10px;
}
</style>