<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <!-- <div v-show="errorType == 1" style="margin-top:70px">
          <p>
            端末を登録する必要があります。
          </p>
      </div>
      <div v-show="errorType == 2" class="err-area" style="margin-top:70px;">
          <div class="err-title">エラー</div>
          <div class="err-content">有効期限が切れています。<br />再度管理画面よりメールアドレスをご登録下さい。</div>
      </div> -->
      <ion-card style="margin-top:70px; padding:15px">
        <ion-card-title style="font-size: 20px; margin-bottom:20px;">送信完了</ion-card-title>
        <div class="sofu-sentence">
          <span>入力いただいた電話番号に紐づくメールアドレス宛にメールを送信いたしました。</span>
        </div>
        <ion-button @click="toLogin" expand="block">ログイン画面へ</ion-button>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCard, IonCardTitle, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import { lockClosedOutline, phonePortraitOutline } from 'ionicons/icons';
//import axios from 'axios';

export default defineComponent({
  name: 'ResendKanryo',
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardTitle,
    IonButton,
  },
  setup(){
    return { lockClosedOutline, phonePortraitOutline }
  },
  data(){
      return {
      };
  },
  methods:{
      toLogin: function () {
        this.$router.push('/login')
      },
  }
});
</script>

<style scoped>
.toroku {
  margin:20px;
}
.err-area{
  border: 4px solid ;
  border-radius: 10px;
  background: #fbe5d6;
  margin: 0 50px;
  padding: 20px 0;
  text-align: center;
  color: #fe3532;
}
.err-title{
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}
.err-content{
  font-size: 15px;
  padding: 0 10px;
}
.inp-style {
  border: solid 0.5px;
  border-color: #ddd;
  border-radius: 5px;
}
.inp-field {
  display:flex;
  align-items:center;
  padding-left:10px;
  padding-bottom:20px;
}
.sofu-sentence{
  padding:10px;
}
</style>