<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
            <ion-card-title class="ion-text-center" size="large">交換</ion-card-title>
        </ion-card-header>
      <ion-card-content>
        <div id="container" style="margin-top: 30px;">
          <div class="menubtn">
              <span class="menu-btn" @click="clickKokan()"><img :src="btn3" width="300" height="104" alt="交換"/></span>
          </div>
          <div class="menubtn">
              <span class="menu-btn" @click="clickIrekae()"><img :src="btn10" width="300" height="104" alt="入替"/></span>
          </div>
          <div class="menubtn">
              <ion-button color="warning" @click="clickBack()">戻る</ion-button>
          </div>

        </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCardTitle, IonButton, IonCard, IonCardContent } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SelectIrekae',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonButton,
    IonCard,
    IonCardContent,
  },
  data(){
      return {
        fFlg: true,
        btn3: require('@/assets/img/mobile_btn3.png'),
        btn10: require('@/assets/img/mobile_btn10.png'),
      };
  },
  ionViewDidEnter(){
    this.$store.commit('iModeOff');
  },
  methods:{
    // 交換画面へ
    clickKokan(){
        this.$router.push('select');
    },
    // 入替画面へ
    clickIrekae(){
        this.$store.commit('iModeOn');
        this.$router.push('select');
    },
    // 戻るボタン
    clickBack(){
      this.$router.push('/menu');
    }
  }
});
</script>

<style scoped>
.menubtn {
    margin: 20px 0;
    text-align: center;
}

.menu-btn {
    cursor: pointer;
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 500;
    letter-spacing: 0.0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143em;
    text-transform: uppercase;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    filter: drop-shadow(2.5px 2.5px 0 rgb(196, 196, 196));
}
.menu-btn:hover {
    cursor: pointer;
    opacity: 0.8;
}
.menu-btn:active {
    filter: drop-shadow(0.5px 0.5px 0 rgb(196, 196, 196));
    transform: translate(2px, 2px) scale3d(1, 1, 1);
}
img {
    image-rendering: -webkit-optimize-contrast;
    -webkit-backface-visibility: hidden;
}
</style>