<template>
    <ion-header>
        <div class="allow-title">
            <ion-toolbar>
                <ion-buttons slot="end">
                    <ion-button @click="closeModal()">
                        <ion-icon name="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </div>
    </ion-header>
    <ion-content class="ion-padding">
        <ion-row>
            <ion-item style="height: 30px; margin-bottom:8px" lines="none">
                <div style="margin-inline: 0px; font-size: small; color: #0000CD;" position="fixed">得意先（コード）</div>
            </ion-item>
        </ion-row>
        <ion-row>
            <ion-item style="width:100%;" lines="none">
                <ion-input class="inp-style" type="name" v-model="inputCd"></ion-input>
            </ion-item>
        </ion-row>
        <ion-row>
            <ion-item style="height: 30px; margin-bottom:8px" lines="none">
                <div style="margin-inline: 0px; font-size: small; color: #0000CD;" position="fixed">得意先名</div>
            </ion-item>
        </ion-row>
        <ion-row>
            <ion-item style="width:100%;" lines="none">
                <ion-input class="inp-style" type="name" v-model="inputTokuiName"></ion-input>
            </ion-item>
        </ion-row>
        <ion-row>
            <ion-item style="height: 30px; margin-bottom:8px" lines="none">
                <div style="margin-inline: 0px; font-size: small; color: #0000CD;" position="fixed">設置現場</div>
            </ion-item>
        </ion-row>
        <ion-row>
            <ion-item style="width:100%;" lines="none">
                <ion-input class="inp-style" type="name" v-model="inputGenbaName"></ion-input>
            </ion-item>
        </ion-row>
        <ion-row>
            <ion-item style="height: 30px; margin-bottom:8px;" lines="none">
                <div style="margin-inline: 0px; font-size: small; color: #0000CD;" position="fixed">コンテナ種類</div>
            </ion-item>
        </ion-row>
        <ion-row>
            <ion-item style="width:100%;" lines="none">
                <optgroup></optgroup>
                <ion-select class="inp-style" style="width:100%;" interface="action-sheet" v-model="selType" ok-text="選択" cancel-text="閉じる">
                    <ion-select-option v-for="contType in contTypeList" :value="contType.type_cd" :key="contType.type_cd">{{ contType.type_name }}[{{ contType.size_name }}]</ion-select-option>
                </ion-select>
            </ion-item>
        </ion-row>
        <ion-row>
            <ion-item style="height: 30px; margin-bottom:8px" lines="none">
                <div style="margin-inline: 0px; font-size: small; color: #0000CD;" position="fixed">コンテナ状態</div>
            </ion-item>
        </ion-row>
            <ion-item lines="none">
                <ion-checkbox class="form-example" style="margin: 3px" v-model="settiStatus" @ionChange="changeStatus" :checked="(contStatus.indexOf(1)==1)"></ion-checkbox>
                <ion-label style="font-size:small;">設置中</ion-label>
            </ion-item>
            <ion-item lines="none">
                <ion-checkbox class="form-example" style="margin: 3px" v-model="koukanStatus" @ionChange="changeStatus"></ion-checkbox>
                <ion-label style="font-size:small;">交換依頼中</ion-label>
            </ion-item>
            <ion-item lines="none">
                <ion-checkbox class="form-example" style="margin: 3px" v-model="tekkyoStatus" @ionChange="changeStatus"></ion-checkbox>
                <ion-label style="font-size:small;">撤去依頼中</ion-label>
            </ion-item>
        <ion-row>
            <ion-button id="jokyo-back" color="primary" style="margin-top:15px; margin-left:auto;" @click="searchMap" >検索</ion-button>
            <ion-button id="jokyo-back" color="danger" style="margin-top:15px; margin-left:auto;" @click="resetSearch" >リセット</ion-button>
            <ion-button id="jokyo-back" color="warning" style="margin-top:15px; margin-left:auto;" @click="closeModal" >閉じる</ion-button>
        </ion-row>
    </ion-content>
</template>

<style  scoped>
.inp-style {
    margin: 0;
    border: solid 0.5px;
    border-color: #ddd;
    border-radius: 5px;
    padding-left: 10px !important;
}
</style>
<script>
import { defineComponent, toRefs, computed, } from "vue";
import {IonButton, IonContent, IonHeader, IonIcon, IonToolbar, IonLabel, IonInput, IonItem, IonButtons, IonRow, IonCheckbox, IonSelect, IonSelectOption } from '@ionic/vue';
export default defineComponent({
    data() {
        return {
            selStatus: [],
            settiStatus: false,
            koukanStatus: false,
            tekkyoStatus: false,
            selType: '',
            inputCd: '',
            inputTokuiName: '',
            inputGenbaName: '',
        };
    },
    components: {
        IonContent,
        IonButton,
        IonHeader,
        IonIcon,
        IonToolbar,
        IonInput,
        IonItem,
        IonButtons,
        IonRow,
        IonLabel,
        IonCheckbox,
        IonSelect,
        IonSelectOption,
    },
    props: {
        cd: Number,
        tokuiName: String,
        genbaName: String,
        contType: String,
        contStatus: Array,
        contTypeList: Object,
    },
    async mounted(){
        if(this.contStatus.includes(1)){
            this.settiStatus=true
        }
        if(this.contStatus.includes(2)){
            this.koukanStatus=true
        }
        if(this.contStatus.includes(3)){
            this.tekkyoStatus=true
        }
        this.selStatus = this.contStatus;
        this.inputCd = this.cd;
        this.inputTokuiName = this.tokuiName;
        this.inputGenbaName = this.genbaName;
        this.selType = this.contType;
    },
    emit: ['update:cd', 'update:tokuiName', 'update:genbaName', 'update:contType', 'update:contStatus'],
    setup(props, {emit}) {
        const {cd} = toRefs(props)
        const cdComputed = computed({
            get: () => cd.value,
            set: (value) => {
                emit('update:cd', value)
            },
        })
        const {tokuiName} = toRefs(props)
        const tokuiNameComputed = computed({
            get: () => tokuiName.value,
            set: (value) => {
                emit('update:tokuiName', value)
            },
        })
        const {genbaName} = toRefs(props)
        const genbaNameComputed = computed({
            get: () => genbaName.value,
            set: (value) => {
                emit('update:genbaName', value)
            },
        })
        const {contType} = toRefs(props)
        const contTypeComputed = computed({
            get: () => contType.value,
            set: (value) => {
                emit('update:contType', value)
            },
        })
        const {contStatus} = toRefs(props)
        const contStatusComputed = computed({
            get: () => contStatus.value,
            set: (value) => {
                emit('update:contStatus', value)
            },
        })
        return {cdComputed, tokuiNameComputed, genbaNameComputed, contTypeComputed, contStatusComputed}
    },
    methods: {
        /**
         * 閉じる
         */
        closeModal() {
            this.$emit("close-modal");
        },
        /**
         * 検索ボタン
         */
        searchMap() {
            this.cdComputed = this.inputCd;
            this.tokuiNameComputed = this.inputTokuiName;
            this.genbaNameComputed = this.inputGenbaName;
            this.contTypeComputed = this.selType;
            this.contStatusComputed = this.selStatus;
            this.$emit("add-marker");
            this.$emit("close-modal");
        },
        /**
         * 配列つくる
         */
        changeStatus() {
            this.selStatus = []
            if(this.settiStatus){
                this.selStatus.push(1)
            }
            if(this.koukanStatus){
                this.selStatus.push(2)
            }
            if(this.tekkyoStatus){
                this.selStatus.push(3)
            }
        },
        resetSearch() {
            this.settiStatus = false;
            this.koukanStatus = false;
            this.tekkyoStatus = false;
            this.inputCd = '';
            this.inputTokuiName = '';
            this.inputGenbaName = '';
            this.selType = '';

        }
    }
})
</script>