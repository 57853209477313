<template>
    <ion-page style="max-width:100%; " >
        <div class="testbar" style="margin-top:10vh" ion-fixed>
                <div style="margin:0px 10px 0px">
                    <div style="height:50px;color:white;background-color: var(--ion-color-secondary);text-align: center; font-size:170%; font-weight: bold; padding-top:8px; border-radius: 8px 8px 0px 0px;">設置</div>
                    <div class="" style="height:30px;color:white; text-align: center; padding-top:3px; font-weight: bold; background-color: var(--ion-color-medium);">抽出結果一覧</div>
                    <div class="" style="height:50px; padding:5px 15px 5px; display: flex; flex-wrap: wrap; width:100%">
                        <div style="width:calc((100%/4)*3);display: flex; align-items: center;">
                            <ion-checkbox style="margin-right: 5px; vertical-align: middle;" v-model="aroundChk" @ionChange="clickSearch"></ion-checkbox>
                            <span style="color:black;font-size: 16px;">現在地周辺で絞り込み</span>
                        </div>
                        <div style="width:calc((100%/4)*1)">
                            <ion-button color="light" style="" @click="clickOpen()">{{ openSearch ? '閉じる' : '詳細検索'}}</ion-button>
                        </div>
                    </div>
                    <div v-if="openSearch"  style="">
                        <ion-grid>
                            <ion-row>
                                <ion-item style="--background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));" lines="none">
                                    <ion-label style="margin-inline: 0px;">コード(得意先)</ion-label>
                                    <ion-input class="inp-style" type="name" v-model="searchCd"></ion-input>
                                </ion-item>
                            </ion-row>
                            <ion-row>
                                <ion-item style="--background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));" lines="none">
                                    <ion-label style="margin-inline: 0px;" position="fixed">名称</ion-label>
                                    <ion-input class="inp-style" type="name" v-model="searchName"></ion-input>
                                </ion-item>
                            </ion-row>
                            <ion-row>
                                <ion-item style="--background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));" lines="none">
                                    <ion-label style="margin-inline: 0px;" position="fixed">住所</ion-label>
                                    <ion-input class="inp-style" type="name" v-model="searchAddress"></ion-input>
                                </ion-item>
                            </ion-row>
                            <ion-row>
                                <ion-item style="--background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));" lines="none">
                                    <ion-label style="margin-inline: 0px;" position="fixed">電話番号</ion-label>
                                    <ion-input class="inp-style" type="name" v-model="searchTel"></ion-input>
                                </ion-item>
                            </ion-row>
                            <ion-row class="ion-justify-content-end">
                                <ion-button style="margin-right: 16px;" color="warning" @click="clickClear">クリア</ion-button>
                                <ion-button @click="clickSearch">検索</ion-button>
                            </ion-row>
                        </ion-grid>
                    </div>
                </div>
        </div>
        <ion-content scroll-y style="">
            <div id="overlay_parts" class="overlay" v-if="isOverlay"></div>
                <p style="text-align:center; width:100%;  color:red; font-size:20px; " v-if="non_data_error!='' && !searchLoading">{{non_data_error}}</p>
            <ion-list>
            <ion-item
            class="listData"
            v-for="(item, index) in genbaList"
            :key="index"
            >
                        <div class="twoRows">
                            <div class="oneRows">
                                <ion-item-group>
                                    <ion-item-divider style="width:100%;" color="secondary" class="driver_pos">
                                        <ion-text class="no_pos">
                                            {{index+1}}
                                        </ion-text>
                                    </ion-item-divider>
                                    <ion-item>
                                        <div class="item_pos">
                                            <p class="item_pos_p">{{item.tokui_name}}</p>
                                        </div>
                                    </ion-item>
                                    <ion-item lines="none">
                                        <div class="item_pos">
                                            <p class="item_pos_p">{{item.genba_name}}</p>
                                        </div>
                                    </ion-item>
                                </ion-item-group>
                            </div>
                            <ion-button class="btn_pos" expand="block" @click="clickSelect(item)">選択</ion-button>
                        </div>
                    </ion-item>
                <div class="loading" v-show="searchLoading">
                    <ion-spinner name="lines"></ion-spinner>
                </div>
            </ion-list>
                    <ion-infinite-scroll
                        @ionInfinite="loadData($event)" 
                        threshold="100px"
                        position="bottom" 
                        id="infinite-scroll"
                        :disabled="isDisabled"
                        style=""
                    >
                        <ion-infinite-scroll-content
                        loading-spinner="bubbles"
                        loading-text="Loading more data...">
                        </ion-infinite-scroll-content>
                    </ion-infinite-scroll>
        </ion-content>
        <ion-footer style="height:90px;margin-bottom: 25px;">
                        <ion-button id="kakutei-back" :disabled="isOverlay" expand="block" color="warning" @click="clickBack()">戻る</ion-button>
                        <ion-button id="kakutei-back" :disabled="isOverlay" expand="block" color="primary" @click="clickKakutei()">設置確定画面へ</ion-button>
        </ion-footer>
    </ion-page>
</template>
<style  scoped>
.testbar {
    width: 100%;
    color: white;
}
.openSearchArea {
    height: 200vh;
}
.kakutei-back {
    margin: 20px;
}
.toolber-font {
    color: #ffffff;
    font-size: medium;
    text-align: center;
    text-size-adjust: 10px;
    width: 100%;
    margin: 0 auto;
}
.card-contents {
    flex: 1;
    margin-top: -20px;
    margin-left: -30px;
    margin-right: auto;
    overflow-x: hidden;
    overflow-y: scroll;
}
.listData {
    width: 100vw;
    background: content-box;
}
.twoRows {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22vh;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}
.oneRows {
    width: 70vw;
    border: 1px solid #92949c;
    margin-right: 10px;
}
.driver_pos {
    font-size: large;
    max-width: 100%;
}
.no_pos {
    font-size: large;
    width: 20%;
}
.item_pos {
    width: 200px;
}
.item_pos_p {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.btn_pos {
    width: 10vw;
    height: 80%;
}
.around {
    display: flex;
    align-items: center;
}
.search-area {
    padding: 15px 15px 15px;
    background-color: white;
    height: 200px;
}
.inp-style {
    margin: 8px 0;
    margin-left: 1cm;
    border: solid 0.5px;
    border-color: #ddd;
    border-radius: 5px;
    --padding-start: 10px;
}
.loading {
    display: flex;
    justify-content: center;
}
.overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
    position: fixed;
}
</style>
<script>
//import {IonContent, IonPage, IonCard, IonCardContent, IonButton, IonCheckbox, IonModal, modalController, IonText, IonItem, IonItemGroup, IonItemDivider, IonList, IonInput } from '@ionic/vue';
import {IonContent, IonPage,   IonButton, IonCheckbox, modalController, IonText, IonItem, IonItemGroup, IonItemDivider, IonList, IonInput } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
//import Modal from '../components/Modal.vue'
//import AllowAccess from '../components/AllowAccess.vue'

export default defineComponent({
  name: 'GenbaList',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonCheckbox,
   // IonModal,
    //Modal,
    //AllowAccess,
    IonText,
    IonItem,
    IonItemGroup,
    IonItemDivider,
    IonList,
    IonInput,
  },
  setup() {
    // 位置情報設定用のダイアログ表示制御
    const isOpenRef = ref(false);
    const isOpenSettingRef = ref(false);
    const setOpen = (state) => isOpenRef.value = state;
    const setSettingOpen = (state) => isOpenSettingRef.value = state;
      const secchiMsg = '設置手続きを続けるには位置情報へのアクセスを許可する必要があります。';
    const vlist = ref([]);
    return { isOpenRef, isOpenSettingRef, setOpen, setSettingOpen, secchiMsg, vlist }
  },
  data(){
      return {
        title:'',
        searchCd: '',
        searchName: '',
        searchAddress: '',
        searchTel: '',
        aroundChk: true,
        genbaList:[],
        non_data_error:'',
        searchLoading: false,
        openSearch: false,
        isDisabled: false,
        page: 1,
        isOverlay: false,
      };
  },

  //初期化 ページがアクティブになった時実行、データを常に最新にする
  ionViewDidEnter(){
    this.init();
  },
  methods:{
      init() {

        const setData = {
            tokuiCd:'', 
            tokuiName:'', 
            genbaCd: '', 
            genbaName: '', 
            genbaAddress: '' 
        }
        this.$store.commit('setRentInfo', setData);

        // 現場選択モードをオンにする
        this.$store.commit('gModeOn');
        if (localStorage.getItem('aroundChk')) {
            this.aroundChk = localStorage.getItem('aroundChk');
        }
        // 初期表示用データ取得
        this.clickSearch();
    },
      clickSearch() {
        this.page=1;
        this.genbaList=[];
        if(this.aroundChk=='true'){
            this.aroundChk = true;
        }
        if(this.aroundChk=='false'){
            this.aroundChk = false;
        }
        localStorage.setItem('aroundChk', this.aroundChk);
        this.searchLoading = true;
        navigator.geolocation.getCurrentPosition(this.searchGenba, this.err);
         this.openSearch  = false;
        this.isDisabled=false;
        this.isOverlay = false;
        //this.clickOpen();
    },
    async searchGenba(position){
        
        /*--------DB接続----------*/
        //エラーメッセージ初期化
        this.non_data_error ='';

        const postData = {
            search_cd: this.searchCd,
            search_name: this.searchName,
            search_address: this.searchAddress,
            search_tel: this.searchTel,
            around_chk: this.aroundChk,
            page: this.page,
            perPage: 10,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        }

//        console.log('page is ' + postData.page)

        this.searchLoading = true;
        const res = await this.axios.post('/mobile/search-genba', postData)
        //データが取得できなかった場合のエラーメッセージ
        if(res.data.result != 0){
            this.searchLoading = false;
            this.non_data_error=res.data.msg;
            this.genbaList=[];
            return;
        }
        if(res.data.genba_list.length == 0){
            this.non_data_error="検索データがありません。";
        }
        this.searchLoading = false;
        this.genbaList = [...this.genbaList, ...res.data.genba_list];
        this.vlist= [...this.genbaList, ...res.data.genba_list];
        if(res.data.genba_count<=this.page*10){
            this.isDisabled=true;
        } else {
            this.isDisabled=false;
        }
    },
    /*
    選択ボタンをクリックして画面遷移する。
    */
    async clickSelect(item){
        // 選択された現場の情報をストアに保存
        const genbaAddress = (item.address1 == null && item.address2 == null) ? '': item.address1 + item.address2;
        const setData = {
            tokuiCd: item.tokui_cd,
            tokuiName: item.tokui_name, 
            genbaCd: item.genba_cd,
            genbaName: item.genba_name,
            genbaAddress: genbaAddress,
        }
        this.$store.commit('setRentInfo', setData);

        this.$router.push('/genba-details');
    },
    clickBack(){
        if(this.$store.state.readQrFlg.getFlg == true){
            this.$store.commit('readQrMode', {getFlg:false})
            this.$router.push('inpcont');
            return;
        }
        this.$router.push('readqr');
    },
    err: function () {
     // this.setOpen(true);
    },
    // 位置情報設定ダイアログを閉じる
    closeModal: function () {
      modalController.dismiss();
    },
    // 位置情報設定ダイアログ表示
    showSetting: function (val) {
      this.$nextTick(() => {
        this.settingType = val;
        this.setSettingOpen(true);
      })
    },
    clickKakutei(){
        this.$router.push('/kakutei');
    },
    //検索欄開閉
      clickOpen: function () {
        this.openSearch = !this.openSearch;
        this.isOverlay =  this.openSearch;
    },
    async loadData(ev) {
        this.page+=1;
        await navigator.geolocation.getCurrentPosition(this.searchGenba, this.err);
        ev.target.complete();
    },
    clickClear(){
        this.searchCd = '';
        this.searchName = '';
        this.searchAddress = '';
        this.searchTel = '';
    }
  }
})
</script>