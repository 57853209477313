<template >
        <ion-page style="max-width:100%; height:auto;">
            <ion-card-header color="secondary">
                設置現場選択
            </ion-card-header>
            <ion-content class="my-custom-class2">
                <ion-card class="title" style="margin-top:20px;">
                    <div class="search-area">
                        <div class="search">
                            <ion-input class="inp-style" :value="searchName" @input="searchName = $event.target.value"></ion-input>
                            <ion-button @click="searchData()">絞込</ion-button>
                        </div>
                    </div>
                    <p style="text-align:center; width:100%;  height:120%; color:red; font-size:20px;" v-if="non_data_error!=''">{{non_data_error}}</p>
                    <ion-card-content v-if="non_data_error==''">
                        <ion-list
                        v-for="item in genbaInfo"
                        :key="item.genba_cd"
                        >
                            <ion-row class="ion-align-items-center">
                                <ion-col size="8">
                                    <span style="font-size:25px;">{{item.genba_name}}</span>
                                </ion-col>
                                <ion-col size="4" class="ion-text-right">
                                    <ion-button style="width:80%;" @click="clickSelect(item)">決定</ion-button>
                                </ion-col>
                            </ion-row>
                        </ion-list>
                    </ion-card-content>
                </ion-card>
            </ion-content>
                <ion-footer style="width:80%; margin:auto; display:block;">
                    <ion-button expand="block" color="warning" @click="closeModal()">キャンセル</ion-button>
                </ion-footer>
        </ion-page>
</template>
<style scoped>
.my-custom-class2 .modal-wrapper {
  width: 90%;
  height: 1680px;
  position:absolute;
  top:5%;
  display:block;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.search-area {
    margin: 15px 15px 30px;
}
.search{
    display: flex;
    margin-bottom: 10px;
}
.inp-style {
  border: solid 0.5px;
  border-color: #ddd;
  border-radius: 5px;
}
</style>
<script>
import {IonContent, IonPage, IonCard,IonCardHeader,IonCardContent, IonButton,IonList} from '@ionic/vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ReserveGenbaModal',
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonButton,
    IonList,
  },
  emits: ['click-select','close-modal'],
  props:{
      tokui_cd:{type:String,default:'tokui_cd'},
  },
  data(){
      return {
        genbaInfo:  {
            genba_cd: '',
            genba_name: '',
        },
        non_data_error:'',
        searchName:'',
      };
  },
  mounted(){
      this.searchData()
  },
  methods:{
      /*
        検索
       */
      async searchData(){
          this.non_data_error=''
        await this.axios.post('/mobile/mobileGenbaList',{
            tokui_cd:this.tokui_cd,
            search_name:this.searchName,
        })
        .then((res) => {
            if(res.data.resultCode!='0'){
                this.genbaInfo={
                    genba_cd:'',
                    genba_name:'',
                }
                this.non_data_error='該当する設置現場がありません'
                return
            }
            this.genbaInfo=res.data.resultData
        });
      },
      /*
        特定のアイテムを選択
       */
     clickSelect(item){
        this.$emit('click-select',item);
      },
      /*
        ダイアログを閉じる
       */
      closeModal(){
        this.$emit('close-modal');
      }
  },
})
</script>