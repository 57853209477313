import { createRouter, createWebHistory } from '@ionic/vue-router';
import Login from '../views/Login.vue';
import Shonin from '../views/Shonin.vue';
import Kakunin from '../views/Kakunin.vue';
import Menu from '../views/Menu.vue';
import Select from '../views/Select.vue';
import SelectIrekae from '../views/SelectIrekae.vue';
import Readqr from '../views/Readqr.vue';
import Reserve from '../views/Reserve.vue';
import ReserveResult from '../views/ReserveResult.vue';
import ReserveDetails from '../views/ReserveDetails.vue';
// import ReserveTokuiModal from '../views/ReserveTokuiModal.vue';
// import ReserveGenbaModal from '../views/ReserveGenbaModal.vue';
import GenbaList from '../views/GenbaList.vue';
import GenbaDetails from '../views/GenbaDetails.vue';
import Inpcont from '../views/Inpcont.vue';
import Kakutei from '../views/Kakutei.vue';
import Kanryo from '../views/Kanryo.vue';
import Jokyo from '../views/Jokyo.vue';
import Photos from '../views/Photos.vue';
import Resend from '../views/Resend.vue';
import ResendKanryo from '../views/ResendKanryo.vue';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login 
  },
  {
    path: '/signup',
    name: 'Shonin',
    component: Shonin 
  },
  {
    path: '/kakunin',
    name: 'Kakunin',
    component: Kakunin 
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu 
  },
  {
    path: '/select',
    name: 'Select',
    component: Select,
    props: true
  },
  {
    path: '/select-irekae',
    name: 'SelectIrekae',
    component: SelectIrekae,
    props: true
  },
  {
    path: '/readqr',
    name: 'Readqr',
    component: Readqr,
    props: true
  },
  {
    path: '/reserve',
    name: 'Reserve',
    component: Reserve,
    props: true
  },
  {
    path: '/reserveresult',
    name: 'ReserveResult',
    component: ReserveResult,
    props: true
  },
  {
    path: '/reservedetails',
    name: 'ReserveDetails',
    component: ReserveDetails,
    props: true
  },
  // {
  //   path: '/reservetokuimodal',
  //   name: 'ReserveTokuiModal',
  //   component: ReserveTokuiModal,
  //   props: true
  // },
  // {
  //   path: '/reservegenbamodal',
  //   name: 'ReserveGenbaModal',
  //   component: ReserveGenbaModal,
  //   props: true
  // },
  {
    path: '/genbalist',
    name: 'GenbaList',
    component: GenbaList,
  },
  {
    path: '/genba-details',
    name: 'GenbaDetails',
    component: GenbaDetails,
    props: true
  },
  {
    path: '/inpcont',
    name: 'Inpcont',
    component: Inpcont,
    props: true
  },
  {
    path: '/kakutei',
    name: 'Kakutei',
    component: Kakutei,
    props: true
  },
  {
    path: '/kanryo',
    name: 'Kanryo',
    component: Kanryo,
    props: true
  },
  {
    path: '/jokyo',
    name: 'Jokyo',
    component: Jokyo 
  },
  {
    path: '/photos',
    name: 'Photos',
    component: Photos 
  },
  {
    path: '/resend',
    name: 'Resend',
    component: Resend 
  },
  {
    path: '/resend-kanryo',
    name: 'ResendKanryo',
    component: ResendKanryo 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
