<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-card class="title">
                <ion-card-header color="secondary">
                    <ion-card-title class="ion-text-center" size="xx-large">設置</ion-card-title>
                </ion-card-header>
                <ion-toolbar color="medium">
                    <ion-card-title class="toolber-font">選択確認画面</ion-card-title>
                </ion-toolbar>
                <ion-card-content lines="inset">
                    <ion-list>
                        <ion-item>
                            <ion-label>予約番号</ion-label>
                            <ion-text>{{reserveInfo.reserve_no}}</ion-text>
                        </ion-item>
                        <ion-item style="margin-top:-0.6em;">
                            <ion-list>
                                <ion-label style="font-size:0.7em">予約日</ion-label>
                                <ion-label style="margin-top:-0.2em; padding-left:0.7em;" class="item-text">{{reserveInfo.reserve_date}}</ion-label>
                            </ion-list>
                        </ion-item>
                        <ion-item style="margin-top:-0.8em;">
                                <ion-list>
                                    <ion-label style="font-size:0.7em">得意先</ion-label>
                                    <ion-label style="margin-top:-0.2em; padding-left:0.7em;" class="item-text">{{reserveInfo.tokui_name}}</ion-label>
                                        </ion-list>
                        </ion-item>
                        <ion-item style="margin-top:-0.8em;">
                            <ion-list >
                                <ion-label style="font-size:0.7em">設置現場</ion-label>
                                <ion-label style="margin-top:-0.2em; padding-left:0.7em;" class="item-text">{{reserveInfo.genba_name}}</ion-label>
                            </ion-list>
                        </ion-item>
                        <ion-item style="margin-top:-0.8em;">
                            <ion-list>
                                <ion-label style="font-size:0.7em">設置現場住所</ion-label>
                                <ion-label style="margin-top:-0.2em; padding-left:0.7em;" class="item-text">{{reserveInfo.address1}}</ion-label>
                            </ion-list>
                        </ion-item>
                        <ion-item style="margin-top:-0.8em;">
                            <ion-list>
                                <ion-label style="font-size:0.7em">コンテナ種類</ion-label>
                                <ion-label style="margin-top:-0.2em; padding-left:0.7em;" class="item-text">{{reserveInfo.container_name}}</ion-label>
                            </ion-list>
                        </ion-item>
                    </ion-list>
                    <ion-footer>
                        <div>
                        <ion-button id="kakutei-back" :disabled="!reserveInfo.lat || !reserveInfo.lng?true:false" expand="block" color="primary" @click="clickKeiro()">経路検索</ion-button>
                        </div><div>
                        <ion-button id="kakutei-back" expand="block" color="primary" @click="clickCode()">QRコード読取</ion-button>
                        </div><div>
                        <ion-button id="kakutei-back" expand="block" color="primary" @click="clickCodeInput()">コンテナ識別番号手入力</ion-button>
                        </div><div>
                        <ion-button id="kakutei-back" expand="block" color="warning" @click="clickBack()">戻る</ion-button>
                        </div>
                    </ion-footer>
                </ion-card-content>
            </ion-card>
        </ion-content>
    </ion-page>
</template>
<style scoped>
.kakutei-back {
  margin:20px;
}
.toolber-font {
  color:#ffffff;
  
  font-size:medium;
  text-align: center;
  text-size-adjust: 10px;
  margin-bottom: 1px;
}
.item-text {
    overflow: hidden;
    padding-top:10px;
    padding-bottom:10px;
}
.btn-inner {
  border:solid;
  flex-flow: colum;
  width:80%;
  
}
</style>
<script>
import {IonContent, IonPage, IonCardTitle, IonCard, IonCardHeader, IonCardContent, IonButton} from '@ionic/vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ReserveDetails',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonButton,
  },
  data(){
      return {
        title:'',
        // search:  {
        //     reserve_no:'',
        //     tokui_cd: '',
        //     genba_cd: '',
        //     genba_address:'',
        //     reserve_date:'',
        // },
        // saveData:  {
        //     reserve_no:'',
        //     tokui_cd: '',
        //     genba_cd: '',
        //     genba_address:'',
        //     reserve_date:'',
        // },
        reserveInfo:[{
            reserve_no:'',
            tokui_cd:'',
            tokui_name:'',
            genba_cd:'',
            genba_name:'',
            address1:'',
            reserve_date:'',
            container_name:'',
            type_name:'',
            size_name:'',
            lat:'',
            lng:'',
        }],
      };
  },

  //ページがアクティブになる直前に実行
  ionViewWillEnter(){
      this.init()
  },
  methods:{
    async init(){
        //TODO ストアに変更
        /*---------前の画面からデータ取得------------*/
        // this.title = this.$route.params.title
        // this.search.reserve_no=this.$route.params.reserve_no
        // this.search.tokui_cd=this.$route.params.tokui_cd
        // this.search.genba_cd=this.$route.params.genba_cd
        // this.search.genba_address=this.$route.params.genba_address
        // this.search.reserve_date=this.$route.params.reserve_date

        //TODO ストアに変更
        //抽出結果一覧の検索条件(保存用)
        // this.saveData.reserve_no=this.$route.params.search_reserve_no
        // this.saveData.tokui_cd=this.$route.params.search_tokui_cd
        // this.saveData.genba_cd=this.$route.params.search_genba_cd
        // this.saveData.genba_address=this.$route.params.search_genba_address
        // this.saveData.reserve_date=this.$route.params.search_reserve_date

        /*--------DB接続----------*/
        // await this.axios.post('/mobile/mobileSearch',{
        //     reserve_no:this.search.reserve_no,
        //     tokui_cd:this.search.tokui_cd,
        //     genba_cd:this.search.genba_cd,
        //     genba_address:this.search.genba_address,
        //     reserve_date:this.search.reserve_date
        // })
        // .then((res) => {
        //     this.reserveInfo=res.data.resultData[0]
        // });
        //this.reserveInfo = this.$store.state.rsvInfo;
        
        //前画面から取得した情報を得る
        // let reserveSave = []
        // reserveSave = this.$store.state.rsvInfo
        //コンテナ名として、type_nameとsize_nameを合成した情報を得る
        // this.reserveInfo.reserve_no=reserveSave.reserve_no
        // this.reserveInfo.reserve_date=reserveSave.reserve_date
        // this.reserveInfo.tokui_cd=reserveSave.tokui_cd
        // this.reserveInfo.tokui_name=reserveSave.tokui_name
        // this.reserveInfo.genba_cd=reserveSave.genba_cd
        // this.reserveInfo.genba_name=reserveSave.genba_name
        // this.reserveInfo.address1=reserveSave.address1
        // this.reserveInfo.container_name=
        //         reserveSave.type_name +
        //         ` [${reserveSave.size_name}]`
        // this.reserveInfo.lat=reserveSave.lat
        // this.reserveInfo.lng=reserveSave.lng

        //コンテナ名として、type_nameとsize_nameを合成した情報を得る
        this.reserveInfo = this.$store.state.rsvInfo;
        this.reserveInfo.container_name = this.reserveInfo.type_name + ` [${this.reserveInfo.size_name}]`;
    },
    /*
    ここから先の処理はこちらのメソッドを使ってください。
     */
    async clickCode(){
        this.$store.commit('setRentInfo', {
            tokuiCd: this.reserveInfo.tokui_cd,
            tokuiName: this.reserveInfo.tokui_name,
            genbaCd: this.reserveInfo.genba_cd,
            genbaName: this.reserveInfo.genba_name,
            genbaAddress: this.reserveInfo.address1,
        });
        this.$router.push('/readqr');
    },
    /*
    ここから先の処理はこちらのメソッドを使ってください。
     */
    async clickCodeInput(){
        this.$store.commit('setRentInfo', {
            tokuiCd: this.reserveInfo.tokui_cd,
            tokuiName: this.reserveInfo.tokui_name,
            genbaCd: this.reserveInfo.genba_cd,
            genbaName: this.reserveInfo.genba_name,
            genbaAddress: this.reserveInfo.address1,
        });
        this.$router.push('/inpcont');
    },
    /**
     * 経路クリック
     */
    clickKeiro(){
        navigator.geolocation.getCurrentPosition(this.jumpMap);
    },

    /**
     * To GoogleMa
     */
    jumpMap(position){
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        window.location.href=`https://www.google.com/maps/dir/?api=1&origin=${lat},${lng}&destination=${this.reserveInfo.lat},${this.reserveInfo.lng}&travelmode=driving`;
    },
    clickBack(){
        // this.$router.push({
        //             name:'ReserveResult',params:{
        //                 title:this.title,
        //                 reserve_no:this.saveData.reserve_no,
        //                 tokui_cd:this.saveData.tokui_cd,
        //                 genba_cd:this.saveData.genba_cd,
        //                 genba_address:this.saveData.genba_address,
        //                 reserve_date:this.saveData.reserve_date
        //             }
        //         })
        this.$store.commit('clearRsvNo');
        this.$store.commit('clearRsvInfo');
        this.$router.back();
    },
  }
})
</script>