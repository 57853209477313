import axios from 'axios';
import router from '../router';

var config = {
  baseURL: 'https://dev-api.web-container.net/api/',
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest'
  }
};
if (document.domain === 'test-mobile.web-container.net') {
  config.baseURL = 'https://test-api.web-container.net/api/';
}
if (document.domain === 'mobile.web-container.net') {
  config.baseURL = 'https://api.web-container.net/api/';
}
if (document.domain === 'dev-mobile.web-container.net') {
  config.baseURL = 'https://dev-api.web-container.net/api/';
}

const _axios = axios.create(config);
_axios.interceptors.request.use(
  config => {
    config.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("device_cd");
    console.log(config)
    return config;
  }
)
//レスポンスの共通化
_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log(error.response);
      /**
       * トークンが期限切れの場合 401が発生する
       * エラー画面にリダイレクト
       */
      router.push("/login");
    }
    return Promise.reject(error);
  }
)
Plugin.install = function (app) {
  app.axios = _axios
  window.axios = _axios
  app.config.globalProperties.axios = _axios
  app.config.globalProperties.$axios = _axios
}

export default Plugin